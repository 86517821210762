import React from "react";
import { Link } from "react-router-dom";
import "assets/styles/home/AcceptHome.scss";
import pagos from "assets/img/pagos-en-linea.png";

import Title from "components/common/sections/Title";
import Clearfix from "components/common/Clearfix";

const AcceptHome = () => {
  return (
    <div className={'acceptWrapper'}>
      <div className={'accept'}>
        <div className={`${'right'} ${'section'} ${'info'}`}>
          <div>
            <Title text={"Acepta pagos en línea"} position={"left"} />
            <p>
              Integra nuestra API y recibe pagos de tus clientes desde tu
              eCommerce
            </p>
            <div className={'action'}>
              <Link to="/ecommerce">Más información</Link>
            </div>
          </div>
        </div>
        <div className={`${'left'} ${'section'}`}>
          &nbsp;
          <img src={pagos} alt="Pagos" />
        </div>
        <Clearfix />
      </div>
    </div>
  );
};

export default AcceptHome;
