import React from "react";

import "assets/styles/HeroFooter.scss";

import mastercard from "assets/img/mastercard.png";
import visa from "assets/img/visa.png";
import amex from "assets/img/amex.png";
import siVale from "assets/img/si-vale.png";
import carnet from "assets/img/carnet.png";
import spei from "assets/img/spei.png";
import oxxo from "assets/img/oxxo.png";
import bbva from "assets/img/bbva.png";

const HeroFooter = ({ center }) => {
  return (
    <div className={center ? 'heroFooterCenter' : 'heroFooter'}>
      <img
        className={'heroFooterBrand'}
        src={mastercard}
        alt={"mastercard"}
      />
      <img className={'heroFooterBrand'} src={visa} alt={"visa"} />
      <img className={'heroFooterBrand'} src={amex} alt={"amex"} />
      <img className={'heroFooterBrand'} src={siVale} alt={"siVale"} />
      <img className={'heroFooterBrand'} src={carnet} alt={"carnet"} />
      <img className={'heroFooterBrand'} src={spei} alt={"spei"} />
      <img className={'heroFooterBrand'} src={oxxo} alt={"oxxo"} />
      <img className={'heroFooterBrand'} src={bbva} alt={"bbva"} />
    </div>
  );
};

export default HeroFooter;
