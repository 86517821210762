import React from "react";

import card from "assets/img/card-shadow.png";
import profile from "assets/img/profile.svg";
import calculator from "assets/img/calculator.svg";
import wallet from "assets/img/wallet.svg";
import lock from "assets/img/lock.svg";
import check from "assets/img/Rectangleblue.svg";

import "assets/styles/ecommerce/RegisterEcommerce.scss";

import TitleLeft from "components/common/sections/TitleLeft";
import Clearfix from "components/common/Clearfix";
import Card from "components/ecommerce/sections/RegisterEcommerceCard";

const RegisterEcommerce = () => {
  return (
    <div className={'registerWrapper'}>
      <div className={'register'}>
        <div className={'header'}>
          <TitleLeft>{"¿Cómo activar tu cuenta?"}</TitleLeft>
          <p>Pasos para activar la pasarela de pagos en tu tienda en línea</p>
        </div>
        <div className={'content'}>
          <div className={'gridSection'}>
            <Card
              title={"REGISTRO"}
              text={""}
              image={profile}
              imageWidth="55px"
              bb
              br
            />
            <Card
              title={"CONFIRMACIÓN"}
              text={"Confirma tu cuenta a través de tu correo o por SMS"}
              image={calculator}
              imageWidth="33px"
              bb
              br
            />
            <Card
              title={"INTEGRACIÓN"}
              text={""}
              image={card}
              imageWidth="55px"
              bb
              br
            />
            <Card
              title={"DOCUMENTOS"}
              text={""}
              image={wallet}
              imageWidth="47px"
              br
            />
            <Card
              title={"VALIDACIÓN"}
              text={""}
              image={lock}
              imageWidth="37px"
              br
            />
            <Card
              title={"APROBACIÓN"}
              text={""}
              image={check}
              imageWidth="30px"
              br
              bt
            />
          </div>
          <div className={'cardSection'}>
            <img src={card} alt={"card"} />
            <p>¡Empieza a cobrar en línea hoy mismo!</p>
          </div>
          <Clearfix />
        </div>
      </div>
    </div>
  );
};

export default RegisterEcommerce;
