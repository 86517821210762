import React, { Fragment } from "react";
import "assets/styles/home/Selects.scss";

const Select = ({ data }) => {
  const renderSelect = data => {
    return data.map((select, index) => {
      return (
        <div key={index} className={'simulatorDataSelect'}>
          <p> {select.title}</p>
          <select name="paymentMethod">
            <option value="">Seleccionar</option>
            {select.options.map((option, index) => {
              return (
                <option key={index} value={index}>
                  {option}
                </option>
              );
            })}
          </select>
        </div>
      );
    });
  };
  
  return <Fragment>{renderSelect(data)}</Fragment>;
}

export default Select;
