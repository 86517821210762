import React from "react";

import "assets/styles/ecommerce/Commissions.scss";

import Title from "components/common/sections/Title";
import Card from "components/ecommerce/sections/CommissionsCard";

import { SIMULATOR_CARDS_ECOMMERCE } from "constants/etomin";

const Commissions = () => {

  const renderCards = () => {
    return SIMULATOR_CARDS_ECOMMERCE.map((card, index) => {
      return (
        <Card
          key={index}
          percentage={card.percentage}
          plus={card.plus}
          plusText={card.plusText}
          availability={card.availability}
          paymentMethod={card.paymentMethod}
        />
      );
    });
  };

  return (
    <div className={'commissionsEcommerceWrapper'}>
      <div className={'commissionsEcommerce'}>
        <Title text="Comisiones" position="Center" />
        <div className={'commissionsEcommerceCardContainer'}>
          {renderCards()}
        </div>
        <p className={'commissionsDisclaimer textCenter'}>
          Oferta sujeta cambios sin previo aviso
        </p>
      </div>
    </div>
  );
}

export default Commissions;
