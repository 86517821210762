import React, { useState } from "react";

import "assets/styles/TermsAndConditions.scss";

import background from "assets/img/bg-pattern.jpg";

import TitleLeft from "components/common/sections/TitleLeft";
import Clearfix from "components/common/Clearfix";

const HeroHome = () => {
    return (
        <div className={'TermsAndConditionsContainer'}>

			<p>
				Para utilizar la página de GRUPO VENDERS MEXICO S.A. DE C.V. (en adelante ETOMIN), sus aplicaciones o cualquiera de sus servicios y por lo tanto comprometerse al cumplimiento de los presentes términos y condiciones usted (EL CLIENTE) debe ser mayor de 18 años y contar con capacidad legal suficiente para ser titular de derechos y obligaciones. Usted manifiesta bajo protesta de decir verdad que los datos con los que se registra son veraces y que lo hace por propio interés y derecho, y no representa a tercero alguno; en el caso de que sea su intención abrir una cuenta de negocio en nombre de una persona moral, acepta y garantiza que tienen facultades suficientes para hacerlo, de lo contrario toda la responsabilidad correrá por su cuenta como persona física.
			</p>

			<p>
				Es importante que antes de iniciar con el registro y por lo tanto de usar los servicios de ETOMIN lea detenidamente los términos y condiciones ya que contienen la manera de operar y los pormenores de la relación entre usted y ETOMIN. Igualmente es recomendable que antes de utilizar los servicios verifique que no hayan cambiado o sido actualizados de forma alguna los términos y condiciones de referencia, lo que aparecerá en la parte superior derecha del presente estableciendo la fecha de la última actualización.
			</p>

			<p>
				Al utilizar los servicios o las aplicaciones proporcionadas por ETOMIN, usted está aceptando las condiciones y cláusulas contempladas en el presente contrato. Cada vez que utilice los servicios de ETOMIN se entenderá que ratifica su voluntad de aplicar estos Términos y Condiciones para regular su relación con ETOMIN y los acuerdos que se encuentran en el presente y en el Aviso de Privacidad correspondiente. En caso de estar en desacuerdo o inconforme con alguno de los puntos que más adelante se describen, no utilice el servicio.
			</p>

			<p>
				Los términos y condiciones que a continuación se establecen pueden ser modificados en el momento que ETOMIN lo considere, de forma unilateral por medio de la publicación de una versión renovada en nuestro sitio web, momento en el que entrará en vigor dicha modificación para todos los usuarios y clientes presentes y futuros de la página y aplicaciones creadas por ETOMIN. Es recomendable que constantemente esté verificando las actualizaciones de los términos y condiciones de referencia, lo que aparecerá en la parte superior derecha del presente documento estableciendo la fecha de la última actualización.
			</p>

			<p>
				Le recordamos que los pagos recibidos en su cuenta podrán revertirse posteriormente por ETOMIN independientemente de que se hayan reflejado en su cuenta o incluso pagado, si existe cualquier tipo de contracargo, cancelación, reclamación o si se invalida por cualquier otra razón, ya que usted será el único responsable de afrontar dichas situaciones.
			</p>

			<p>
				Igualmente le recordamos que las aplicaciones de ETOMIN van dirigidas a apoyarlo en su negocio por medio de los servicios que presta pero usted es el único responsable de cualquier relación que adquiera y de su negocio en sí mismo, razón por la cual desde este momento libera a ETOMIN de cualquier tipo de responsabilidad presente o futura que pudiera surgir por la prestación del servicio, suspensión o inexactitud del mismo. Usted es el único responsable de comprender y dar cumplimiento a la legislación aplicable y desde este momento se compromete a que el uso que dé a las aplicaciones y servicios de ETOMIN tendrá que ver en todo momento con negocios y operaciones lícitas bajo su más estricta supervisión y responsabilidad.
			</p>

			<p>
				ETOMIN se reserva el derecho de suspender la cuenta o el servicio completo en cualquier momento y sin previo aviso, en cuyo caso no seremos responsables de pérdidas, igualmente nos reservamos el derecho de hacer las devoluciones a las personas que hubieran procesado pagos a su favor.
			</p>

			<p>
				El CLIENTE manifiesta desde este momento que todos los datos proporcionados son veraces y correctos, en el entendido de que en caso contrario se suspenderá de inmediato el servicio.
			</p>

			<p>
				El CLIENTE autoriza a ETOMIN para realizar cualquier investigación tendiente a identificar y corroborar sus datos, además de autorizar expresamente a ETOMIN para realizar una investigación por medio del Buró de Crédito.
			</p>

			<p>
				Las partes están de acuerdo en aplicar las siguientes definiciones para efectos de interpretación del presente contrato:
			</p>

			<p>
				SERVICIOS: Aquellos descritos en la cláusula primera.
			</p>

			<p>
				CONTRACARGO: Procedimiento mediante el cual se presenta el reclamo de un tarjetahabiente que manifiesta haber recibido un cargo en su Tarjeta de Crédito y/o Débito que no le corresponde, ya que no fue debidamente autorizado por dicho tarjetahabiente o fue efectuado en contravención a lo establecido en este contrato; de aplicar se procede con el reembolso al tarjetahabiente las cantidades cargadas indebidamente a su cuenta.
			</p>

			<p>
				CLIENTE: Establecimiento comercial, ya sea persona física o moral que tiene como objeto principal realizar actividades empresariales y es la persona que registró y solicitó el servicio y que se compromete, por ser así su voluntad, a aplicar y pasar el presente convenio como Derecho aplicable para su relación con ETOMIN.
			</p>

			<p>
				ETOMIN: Servicio de Pasarela de Pagos.
			</p>

			<p>
				VENDERS: GRUPO VENDERS MEXICO S.A. DE C.V. el proveedor del servicio y el administrador de la plataforma ETOMIN.
			</p>

			<div className={'titlePoint'}>
				1.- NUESTROS SERVICIOS
			</div>

			<p>
				ETOMIN es un proveedor de servicio de gestión de cobros y pagos y lo hace a través de Terminal Punto de Venta Virtual (TPV VIRTUAL), TERMINAL PUNTO DE VENTA (TPV), PAGO EN EFECTIVO Y TRANSFERENCIA INTERBANCARIA de las ventas y/o servicios que realice y preste el CLIENTE.
			</p>

			<p>
				Al aceptar estos Términos, el Usuario confiere a ETOMIN una comisión mercantil de conformidad con el Código de Comercio vigente en la República Mexicana con el fin de que ETOMIN preste el Servicio. En específico para recibir pagos por cuenta y en nombre del CLIENTE y administrar fondos del CLIENTE.
			</p>

			<div className={'subTitlePoint'}>
				1.1 TPV VIRTUAL
			</div>

			<p>
				ETOMIN prestará el servicio de Terminal Punto de Venta Virtual, por el que ETOMIN gestionará los cobros con tarjetas de crédito y de débito a nombre del CLIENTE que por medio de internet en la plataforma proporcionada por ETOMIN se realicen, registrará los importes recibidos y posteriormente liquidará al CLIENTE los importes cobrados depositando en la cuenta bancaria designada, reteniendo la comisión correspondiente de conformidad con la cláusula CUARTA y haciendo la retención temporal correspondiente a la garantía estipulada en la cláusula OCTAVA.
			</p>

			<div className={'subTitlePoint'}>
				1.2 TPV
			</div>

			<p>
				ETOMIN prestará el servicio de Terminal Punto de Venta, por el que ETOMIN gestionará los cobros con tarjetas de crédito y de débito de las ventas que haga el CLIENTE en su local comercial físico y con una terminal electrónica lectora de tarjetas conectada a internet, registrará los importes recibidos por ETOMIN en virtud de las operaciones de pago realizadas y remitirá dichos importes a la cuenta bancaria designada por el CLIENTE, reteniendo la comisión correspondiente de conformidad con la cláusula CUARTA y la retención temporal correspondiente a la garantía reconocida en la cláusula OCTAVA.
			</p>

			<div className={'subTitlePoint'}>
				1.3 COBRO EN EFECTIVO
			</div>

			<p>
				ETOMIN recibe pagos en efectivo por cuenta del CLIENTE, en establecimientos autorizados por ETOMIN. Se hace saber al CLIENTE la persona y el monto recibido depositando con posterioridad en la cuenta bancaria designada por el CLIENTE, reteniendo la comisión correspondiente de conformidad con la cláusula CUARTA y la retención temporal correspondiente a la garantía reconocida en la cláusula OCTAVA.
			</p>

			<div className={'subTitlePoint'}>
				1.4 TRANSFERENCIA BANCARIA
			</div>

			<p>
				ETOMIN recibe, gestiona y administra pagos electrónicos por transferencia bancaria (SPEI) a nombre y por cuenta del CLIENTE, identificando el monto y la persona que realiza dicho pago y remitiendo dichos importes a la cuenta bancaria designada por el CLIENTE, reteniendo la comisión correspondiente de conformidad con la cláusula CUARTA y la retención temporal correspondiente a la garantía reconocida en la cláusula OCTAVA.
			</p>

			<div className={'titlePoint'}>
				2.- CONDICIONES PARA EL USO DEL SERVICIO Y LIMITACIONES DEL MISMO
			</div>

			<div className={'subTitlePoint'}>
				2.1 CALIDAD DEL TRABAJO
			</div>

			<p>
				ETOMIN responderá de la calidad del trabajo desarrollado y de la custodia del dinero cobrado, con la diligencia exigible a una empresa experta en la realización de los servicios objeto del Contrato.
			</p>

			<div className={'subTitlePoint'}>
				2.2 GESTIÓN DE LICENCIAS, PERMISOS y AUTORIZACIONES
			</div>

			<p>
				ETOMIN gestionará y obtendrá, a su cargo, todas las licencias, permisos y autorizaciones administrativas que pudieren ser necesarias para la realización de los Servicios.
			</p>

			<div className={'subTitlePoint'}>
				2.3 CONTRATO
			</div>

			<p>
				EL CLIENTE está consciente y se da por enterado que dada la naturaleza del servicio objeto del presente contrato, los impuestos causados por las diversas operaciones así como las obligaciones fiscales secundarias seguirán corriendo por cuenta del CLIENTE, por ninguna razón ETOMIN se subrogará o se solidarizará en las obligaciones fiscales del CLIENTE.
			</p>

			<div className={'subTitlePoint'}>
				2.4 ACERCA DE ETOMIN
			</div>

			<p>
				El CLIENTE tiene conocimiento de que ETOMIN no es una entidad de pago, banco o caja de ahorro. ETOMIN se encarga de gestionar el cobro de la venta o servicios prestados por el CLIENTE por los medios establecidos en la cláusula PRIMERA del presente instrumento.
			</p>

			<div className={'subTitlePoint'}>
				2.5 SERVICIO DE ATENCIÓN AL CLIENTE
			</div>

			<p>
				ETOMIN dispone de un servicio de atención al CLIENTE a través del siguiente correo electrónico: soporte@etomin.com
			</p>

			<div className={'subTitlePoint'}>
				2.6 COBROS Y TRANSFERENCIAS
			</div>

			<p>
				Los cobros y transferencias realizados por el CLIENTE a través de los instrumentos de pago que se establecen en el presente contrato, ingresarán en una cuenta bancaria de ETOMIN y posteriormente y previa retención de la comisión y garantía establecidas en la cláusula CUARTA y OCTAVA respectivamente, se reintegrarán a la cuenta designada por el CLIENTE.
			</p>

			<div className={'subTitlePoint'}>
				2.7 DEPÓSITOS
			</div>

			<p>
				El CLIENTE no percibirá interés o beneficio alguno de la cuenta en la que se deposita ninguno de los fondos.
			</p>

			<div className={'subTitlePoint'}>
				2.8 LIQUIDACIÓN
			</div>

			<p>
				La liquidación al CLIENTE de los cobros gestionados por ETOMIN se realizará a través de transferencia bancaria a la cuenta designada por el CLIENTE.
			</p>

			<div className={'subTitlePoint'}>
				2.9 SOLICITUD DEL DEPÓSITO
			</div>

			<p>
				El CLIENTE podrá solicitar el depósito de los cobros de forma gratuita una vez a la semana, en caso de que necesite disponer de fondos con mayor frecuencia, se cobrará una comisión adicional de $8.00 (OCHO PESOS 00/100 M.N.) más el Impuesto al Valor Agregado correspondiente.
			</p>

			<div className={'subTitlePoint'}>
				2.10 DECLARACIÓN BAJO PROTESTA
			</div>

			<p>
				El CLIENTE declara bajo protesta de decir verdad que es propietario de los productos y servicios que pone a la venta o en el supuesto de no ser propietario de los productos tiene la debida autorización para su venta. Igualmente el CLIENTE libera a ETOMIN de cualquier tipo de responsabilidad presente o futura por lo que a su negocio se refiere entendiendo que ETOMIN exclusivamente opera como gestor de cobros y no tiene manera de enterarse ni tener conocimiento de las operaciones que den origen a dichos pagos.
			</p>

			<div className={'subTitlePoint'}>
				2.11 SOLICITUDES DE CONTRACARGO
			</div>

			<p>
				EL CLIENTE es responsable de documentar y responder a las solicitudes de contracargo y/o fraude que se pudieran presentar. ETOMIN facilitará el servicio de trámite ante las instituciones bancarias pero no asume responsabilidad por contracargos y/o fraudes, razón por la cual no asumirá costos por estos conceptos.
			</p>

			<div className={'subTitlePoint'}>
				2.12 DETALLES DE CONTRACARGO
			</div>

			<p>
				Las partes deben revisar los detalles del contracargo y si es posible enviar las pruebas a la entidad emisora de la tarjeta de débito o crédito para llevar a cabo el proceso de aclaración de acuerdo con los tiempos y en los términos establecidos en la regulación aplicable. La entidad emisora de la tarjeta de débito o crédito es la responsable de determinar la resolución del contracargo.
			</p>

			<div className={'subTitlePoint'}>
				2.13 RESTITUCIÓN
			</div>

			<p>
				En el caso anterior o cuando por cualquier situación o circunstancia haya que restituir a la persona que pagó el monto pagado, EL CLIENTE desde este momento autoriza a ETOMIN para descontar de cualquier saldo a favor que EL CLIENTE tenga con ETOMIN el importe de la transacción más las comisiones generadas por esta circunstancia.
			</p>

			<div className={'subTitlePoint'}>
				2.14 REQUERIMIENTO
			</div>

			<p>
				En el caso de que el numeral anterior no sea aplicable; el CLIENTE se obliga a pagar a ETOMIN, dentro de los cinco días siguientes al requerimiento que deberá realizar ETOMIN al CLIENTE las cantidades y comisiones objeto de la operación u operaciones retractadas. Pasado el término antes citado se causarán intereses moratorios a razón del 5% mensual, mismos que serán aplicados preferentemente al pago que se realice.
			</p>

			<div className={'subTitlePoint'}>
				2.15 DATOS
			</div>

			<p>
				El CLIENTE será el único responsable de recabar los datos del comprador y del envío del pedido a la dirección suministrada por el comprador, razón por la cual será el único responsable de determinar la identidad e idoneidad de los usuarios con los que pueda interactuar a través de los Servicios objeto del presente contrato. ETOMIN no investiga o verifica la reputación, la conducta, la moral, antecedentes penales, comerciales, de crédito o cualquier información que un participante pudiera presentar a través de los Servicios. ETOMIN no hace representación o garantías de ningún tipo con respecto a ningún usuario registrado para utilizar los Servicios. EL CLIENTE es el único responsable de la investigación y selección de sus propios clientes y relaciones que por medios virtuales y presenciales establezca.
			</p>

			<div className={'subTitlePoint'}>
				2.16 RESPONSABILIDAD DEL SERVICIO
			</div>

			<p>
				El CLIENTE es el único responsable de garantizar sus productos y/o servicios que preste, razón por la cual ETOMIN se deslinda de cualquier responsabilidad respecto a operaciones, negocios y relaciones establecidas por EL CLIENTE, aun aquellas en las que utilice las herramientas proporcionadas por ETOMIN.
			</p>

			<div className={'subTitlePoint'}>
				2.17 PRIVACIDAD
			</div>

			<p>
				El CLIENTE no podrá utilizar el nombre de ETOMIN, salvo para informar al comprador de la pasarela de pagos que utiliza.
			</p>

			<div className={'subTitlePoint'}>
				2.18 ESTADO DE CUENTA
			</div>

			<p>
				ETOMIN se compromete a que el CLIENTE podrá acceder y verificar en todo momento su estado de cuenta.
			</p>

			<div className={'subTitlePoint'}>
				2.19 SERVICIOS
			</div>

			<p>
				ETOMIN ejecutará el contrato realizando de manera competente y profesional los Servicios, cumpliendo los niveles de calidad exigidos.
			</p>

			<div className={'subTitlePoint'}>
				2.20 CONTACTO
			</div>

			<p>
				ETOMIN se reserva el derecho de contactar al titular de la tarjeta con la que se esté realizando cualquier tipo de transacción para asegurarse que sea el legítimo propietario y evitar cualquier tipo de fraude o ilícito. El CLIENTE se compromete a hacer del conocimiento de los titulares de las tarjetas o pagadores por cualquier medio esta situación.
			</p>

			<div className={'titlePoint'}>
				3.- POLÍTICA DE USO
			</div>

			<div className={'subTitlePoint'}>
				3.1 RESPONSABILIDAD DEL CLIENTE
			</div>

			<p>
				El CLIENTE es el único responsable de determinar si los servicios que constituyen el objeto de estos Términos y Condiciones se ajustan a sus necesidades.
			</p>

			<div className={'subTitlePoint'}>
				3.2 RELACION DEL CLIENTE
			</div>

			<p>
				El CLIENTE exonera a ETOMIN de cualquier obligación y responsabilidad respecto a la relación entre el CLIENTE y su usuario o de cualquier otra responsabilidad frente a terceros derivada de la relación entre el CLIENTE y sus usuarios.
			</p>

			<div className={'subTitlePoint'}>
				3.3 INTERRUPCIÓN EN LOS SERVICIOS
			</div>

			<p>
				ETOMIN no garantiza ni será responsable de las interrupciones en los servicios objeto del presente contrato por causas ajenas a ETOMIN o por fuerza mayor.
			</p>

			<div className={'subTitlePoint'}>
				3.4 TIEMPO DE ESPERA DE PAGO
			</div>

			<p>
				ETOMIN no se responsabiliza por el tiempo de espera en el proceso de pago, ni por los resultados que ofrezca en los servicios la Entidad de Pago. Cualquier incidencia debida al número de la tarjeta de crédito y su introducción en el sistema deberá ser resuelta con la Entidad de Pago emisora de dicha tarjeta.
			</p>

			<div className={'subTitlePoint'}>
				3.5 RESPONSABILIDAD SOBRE PRODUCTOS
			</div>

			<p>
				El CLIENTE declara y se responsabiliza de que los productos cobrados a través de este servicio no constituyen actividad delictiva y cumplen con la normatividad vigente aplicable a los mismos y deslinda de cualquier responsabilidad presente o futura, civil o penal a ETOMIN.
			</p>

			<div className={'subTitlePoint'}>
				3.6 MEDIDAS DE CONTROL
			</div>

			<p>
				El CLIENTE autoriza a ETOMIN realizar las medidas de control que considere oportunas para comprobar la legalidad de las transacciones efectuadas a través de sus servicios.
			</p>

			<div className={'subTitlePoint'}>
				3.7 RESPONSABILIDAD SOBRE ENVÍOS/ENTREGAS
			</div>

			<p>
				ETOMIN no se responsabiliza del envío o de la entrega de los productos vendidos y cobrados a través de sus servicios o de la calidad de los servicios que a su vez el CLIENTE proporcione a sus usuarios.
			</p>

			<div className={'subTitlePoint'}>
				3.8 RESPONSABILIDAD DEL CLIENTE SOBRE LA VENTA DE PRODUCTOS
			</div>

			<p>
				El CLIENTE se obliga a hacer constar de forma clara, visible y accesible desde sus contenidos, sus datos identificativos y como único responsable de la venta de los productos ofrecidos. Deberá incluir en su página web o en su local un aviso de las Políticas de Uso.
			</p>

			<div className={'subTitlePoint'}>
				3.9 DISPUTA SOBRE PAGOS/TRANSFERENCIAS
			</div>

			<p>
				EL CLIENTE reconoce y acepta que cualquier disputa que pueda tener sobre los Pagos y la recepción de las transferencias será resuelta exclusivamente entre El CLIENTE y sus usuarios, y que ETOMIN no es responsable de pago alguno o de las operaciones de transferencia, incluyendo, sin limitación, cualquier información personal o de pago que EL CLIENTE proporciona.
			</p>

			<div className={'subTitlePoint'}>
				3.10 GARANTÍAS
			</div>

			<p>
				ETOMIN no ofrece ningún tipo de garantía en cuanto a la ejecución o la disponibilidad ininterrumpida de los servicios o los materiales de ETOMIN. Los Servicios y materiales de ETOMIN se proporcionan como se refleja en el presente contrato y según disponibilidad sin garantía de ningún tipo, ya sean expresas o implícitas. ETOMIN niega cualquier tipo de garantía expresa o implícita incluyendo los servicios y la información, el contenido y los materiales contenidos en el mismo. En este acto se manifiesta que los servicios pueden no ser exactos, completos, confiables, actuales o libres de errores. ETOMIN se compromete a realizar todo lo que se encuentre en sus manos para hacer lo más seguro y confiable el acceso y uso de los servicios, no obstante lo anterior.
			</p>

			<div className={'subTitlePoint'}>
				3.11 MODIFIACIÓN DE CONTENIDOS
			</div>

			<p>
				ETOMIN se reserva el derecho de modificar todos y cada uno de los contenidos en los Servicios en cualquier momento sin previo aviso.
			</p>

			<div className={'subTitlePoint'}>
				3.12 REFERENCIA
			</div>

			<p>
				La referencia a cualquier producto, servicio, proceso u otra información, por nombre comercial, marca registrada, fabricante, proveedor u otro no constituye o implica respaldo, patrocinio o recomendación de los mismos o cualquier afiliación con estos por parte de ETOMIN.
			</p>

			<div className={'subTitlePoint'}>
				3.13 TÉRMINOS Y CONDICIONES DEL CLIENTE
			</div>

			<p>
				El CLIENTE acepta defender, indemnizar y eximir de responsabilidad a ETOMIN, sus contratistas independientes, proveedores de servicios y consultores, y sus respectivos directores, empleados y agentes, de y contra cualquier reclamación, daños, costos, responsabilidad y gastos (incluyendo, pero no limitado a honorarios razonables de abogados) que surjan de o en relación con:
			</p>

			<p>
				El uso de los servicios
			</p>
			<p>
				La violación del presente contrato
			</p>
			<p>
				La violación de cualquier derecho a ETOMIN o a terceros.
			</p>
			<div>

			</div>

			<div className={'titlePoint'}>
				4.- PRECIO Y FACTURACIÓN
			</div>

			<p>
				EL CLIENTE se compromete a pagar las cantidades y comisiones establecidas en la lista de precios de nuestra plataforma misma que obra como parte íntegra del presente contrato.
			</p>

			<p>
				EL CLIENTE autoriza de forma expresa a ETOMIN para que realice de forma unilateral, automática y en cada operación, la retención correspondiente al porcentaje que de acuerdo con la lista de precios corresponda.
			</p>

			<p>
				El CLIENTE está de acuerdo en que ETOMIN se reserva el derecho de modificar dicha lista en cualquier momento.
			</p>

			<div className={'titlePoint'}>
				5.- PROPIEDAD INTELECTUAL
			</div>

			<p>
				Las partes convienen que el presente instrumento no otorga a las mismas licencia alguna, o algún tipo de derecho respecto de la “Propiedad Intelectual” de la parte contraria. Para efectos de este convenio, “Propiedad Intelectual” incluye todas las marcas registradas y/o usadas en México o en el extranjero por cualquiera de las partes, así como todo derecho sobre invenciones (patentadas o no), diseños industriales, modelos de utilidad, información confidencial, nombres comerciales, avisos comerciales, reservas de derechos, nombres de dominio, así como todo tipo de derechos patrimoniales sobre obras y creaciones protegidas por derechos de autor y demás formas de propiedad industrial o intelectual reconocida o que lleguen a reconocer las leyes correspondientes.
			</p>

			<p>
				Cada una de las partes se obliga a no usar, comercializar, revelar a terceros, distribuir, regalar, o de cualquier otro modo disponer de cualquier desarrollo realizado por la otra parte, ni de cualquier material o material excedente que sea resultado de la Propiedad Intelectual, sin tener permiso previo y por escrito de la parte titular; mismos que una vez concluida la vigencia del presente convenio, deberán ser devueltos a su propietario.
			</p>

			<p>
				Queda estrictamente prohibido para cada una de las partes, y para su personal en su caso, reproducir sin permiso de la parte propietaria, cualquier tipo de material que se le hubiese proporcionado o desarrollado al amparo del presente convenio, bajo pena de incurrir en alguna sanción establecida en las leyes en materia de derechos de autor, además de la rescisión del presente convenio.
			</p>

			<p>
				Asimismo EL CLIENTE se compromete a no hacer, creer o suponer la existencia de una asociación o relación entre EL CLIENTE y ETOMIN, o que la fabricación de un producto y/o la prestación de un servicio se realiza bajo ciertas normas, licencia o autorización de ETOMIN, o que presta un servicio con autorización o licencia de éste.
			</p>

			<p>
				EL CLIENTE desliga de toda responsabilidad a ETOMIN frente a terceros por cualquier violación a los derechos de autor y propiedad que cometa EL CLIENTE al llevarse a cabo el objeto de este convenio. EL CLIENTE será responsable de obtener y cubrir todos los pagos y obtener las autorizaciones necesarias para cumplir con el objeto del presente convenio, así como sacar en paz y a salvo a ETOMIN de cualquier reclamación y a responder ante las autoridades competentes.
			</p>

			<p>
				Adicionalmente, las partes se obligan a no hacer mal uso de la imagen, logotipos, tipografía, marcas, diseños o imágenes en la publicidad, obligándose a retirarla inmediatamente y a corregir dicho material publicitario en un plazo no mayor a 3 (tres) días posteriores al momento en que se solicite la corrección por escrito de dicho material publicitario.
			</p>

			<div className={'subTitlePoint'}>
				5.1 Derechos de Autor:
			</div>

			<p>
				A menos que se indique lo contrario, los servicios y todo el contenido y otros materiales en los Servicios, incluyendo, sin limitación, el logotipo de la empresa y todos los diseños, textos, gráficos, imágenes, información, datos, software, archivos de sonido, archivos de otro tipo y su selección y disposición son propiedad exclusiva de ETOMIN y se encuentran protegidos por las leyes de derechos de autor mexicanas e internacionales.
			</p>

			<div className={'subTitlePoint'}>
				5.2 Marcas:
			</div>

			<p>
				El CLIENTE no podrá utilizar de forma alguna metatags o cualquier otro “texto oculto” utilizando las Marcas de ETOMIN, sin el previo consentimiento por escrito. Además, el aspecto y la sensación de los Servicios, incluyendo todos los encabezados de página, gráficos personalizados, íconos de botones y secuencias de comandos, son la marca de servicio, marca registrada y/o imagen comercial de ETOMIN y forman parte de las marcas de ETOMIN y no pueden ser copiados, imitados o utilizados en todo o en partes, sin el previo consentimiento por escrito.
			</p>

			<div className={'subTitlePoint'}>
				5.3 Derecho limitado:
			</div>

			<p>
				Se concede un derecho limitado, no exclusivo para crear un hipervínculo de texto a los servicios, siempre que dicho vínculo no represente ETOMIN o cualquiera de sus servicios de una manera falsa, engañosa, despectiva o difamatoria y se indique que el sitio de enlace no contiene ningún material para adulos o ilegal, o cualquier material que sea ofensivo, de acoso o de otra manera. Este derecho limitado puede ser revocado en cualquier momento. El CLIENTE no puede utilizar ninguna marca de ETOMIN u otro gráfico para acceder a los Servicios sin el permiso expreso y por escrito de ETOMIN, además no puede utilizar, enmarcar o utilizar técnicas de enmarcado para adjuntar cualquier Marca de ETOMIN. El CLIENTE tampoco podrá sin consentimiento utilizar el contenido de cualquier texto o la disposición/diseño de cualquier página o formulario contenido en una de las páginas de ETOMIN
			</p>

			<div className={'titlePoint'}>
				6.- CONFIDENCIALIDAD
			</div>

			<div className={'subTitlePoint'}>
				6.1 CONFIDENCIALIDAD
			</div>

			<p>
				ETOMIN y el CLIENTE guardarán confidencialidad sobre la información que se facilite en o para la ejecución de los Servicios o que por su propia naturaleza deba ser tratada como tal. Y se comprometen a no utilizarla en su propio beneficio o en beneficio de terceros. Se excluye de la categoría de información confidencial toda aquella información que sea divulgada por ETOMIN o por el CLIENTE y aquella que haya de ser revelada de acuerdo con las leyes o con una resolución judicial o acto de autoridad competente.
			</p>

			<div className={'subTitlePoint'}>
				6.2 PRESTACIÓN DE LOS SERVICIOS
			</div>

			<p>
				En el caso de que la prestación de los Servicios suponga la necesidad de acceder a datos de carácter personal, las partes se someterán a las Políticas de Privacidad establecidas en el Aviso que se encuentra a disposición en la página de internet de ETOMIN.
			</p>

			<p>
				ETOMIN responderá, por tanto, de las infracciones en que pudiera incurrir en el caso de que destine los datos personales a otra finalidad, los comunique a un tercero, o en general, los utilice de forma irregular, así como cuando no adopte las medidas correspondientes para el almacenamiento y custodia de los mismos.
			</p>

			<p>
				ETOMIN únicamente tratará los datos de carácter personal a los que tenga acceso conforme a las instrucciones del CLIENTE y no los aplicará o utilizará con un fin distinto al objeto de este contrato, ni los comunicará, ni siquiera para su conservación, a otras personas.
			</p>

			<p>
				ETOMIN adoptará las medidas de índole técnica y organizativas necesarias que garanticen la seguridad de los datos de carácter personal y eviten su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos, ya provengan de la acción humana o del medio físico o natural.
			</p>

			<div className={'subTitlePoint'}>
				6.3 DAÑOS Y PREJUICIOS
			</div>

			<p>
				ETOMIN responderá de los daños y perjuicios que se deriven para el CLIENTE y de las reclamaciones que pueda realizar un tercero, y que tengan su causa directa en errores de ETOMIN o de su personal, en la ejecución de este contrato o que deriven de la falta de diligencia referida anteriormente.
			</p>

			<div className={'subTitlePoint'}>
				6.4 OBLIGACIONES
			</div>

			<p>
				Las obligaciones establecidas para ETOMIN y para el CLIENTE por la presente cláusula serán también de obligado cumplimiento para sus posibles empleados, colaboradores, tanto externos como internos, y subcontratistas.
			</p>

			<div className={'subTitlePoint'}>
				6.5 CONTRATO
			</div>

			<p>
				No obstante lo anterior y tomando en consideración la naturaleza del presente contrato EL CLIENTE consiente y autoriza a ETOMIN para que comparta con terceros proveedores la información necesaria para prestar el servicio que hoy se contrata.
			</p>

			<div className={'titlePoint'}>
				7.- RESPONSABILIDAD PATRONAL
			</div>

			<p>
				Las partes acuerdan que este convenio no podrá interpretarse de manera alguna como constitutivo de cualquier tipo de asociación o vínculo de carácter laboral entre las partes; por lo que las relaciones laborales, ya sean de naturaleza individual o colectiva, se mantendrán en todos los casos entre la parte contratante y sus respectivo personal, aun en los casos de los trabajos realizados conjuntamente y que se desarrollen en el lugar o lugares donde se deba desarrollar el objeto del presente convenio y/o con equipo de cualquiera de las partes.
			</p>

			<p>
				En ningún caso podrá considerarse a la contraparte como patrón sustituto, solidario ni tampoco intermediario con respecto a su personal. Las partes se liberan de toda responsabilidad laboral, ya sea de naturaleza individual o colectiva, fiscal, de seguridad social, administrativa, penal y de cualquier otra naturaleza jurídica que al respecto pudiera existir. En consecuencia, cada una de las partes asume totalmente las obligaciones de las relaciones laborales con sus trabajadores, entre ellas, el pago de salario, el pago de las cuotas obrero patronales al Instituto Mexicano del Seguro Social, la retención y entero del impuesto sobre la renta, así como el otorgamiento de las prestaciones a que tengan derecho los trabajadores a su servicio. Por lo tanto, las partes se liberan de cualquier tipo de responsabilidad y se obligan a responder por cualquier demanda o reclamación que se promueva en su contra, así como a correr con todos los gastos y honorarios en que, por estos conceptos y por cualquier otro, pudiesen incurrir con motivo o como consecuencia del ejercicio de la acción legal de que se trate y, en general, de las que por su naturaleza sean a su cargo.
			</p>

			<div className={'titlePoint'}>
				8.- GARANTÍA
			</div>

			<p>
				EL CLIENTE autoriza expresamente a ETOMIN para realizar una retención proporcional de cada operación y que servirá para garantizar el cumplimiento del presente contrato y cualquier otra incidencia en la cual, por responsabilidad del CLIENTE, ETOMIN tenga que responder económicamente.
			</p>

			<p>
				Dicha retención se llevará a cabo de la siguiente manera:
			</p>

			<p>
				ETOMIN retendrá por el término de 90 días el equivalente al 8% del valor de todas y cada una de las operaciones o pagos recibidos por cuenta del CLIENTE. Transcurrido el plazo antes citado ETOMIN devolverá el monto retenido en su totalidad, siempre y cuando no haya sido necesario utilizarlo en los términos establecidos en la presente cláusula.
			</p>

			<p>
				En ningún caso el monto total de las retenciones podrá exceder de $180,000.00 (CIENTO OCHENTA MIL PESOS 00/100 M.N). Cuando se alcance dicha suma, ETOMIN dejará de retener hasta en tanto, por virtud de las devoluciones periódicas a que se refiere el párrafo anterior, el monto de lo retenido disminuya de dicha suma, supuesto en el que ETOMIN estará autorizado para continuar realizando las retenciones conducentes.
			</p>

			<p>
				Dicha garantía servirá para afrontar cualquier adeudo que el CLIENTE tenga con ETOMIN o contracargo, reclamación o reintegro que un tercero exija por mencionar algunos casos de forma enunciativa y no limitativa.
			</p>

			<p>
				El CLIENTE autoriza a ETOMIN para hacer uso de dicha retención total o parcialmente de forma unilateral, bajo su más amplio criterio y sin necesidad de notificar al CLIENTE previa utilización del mismo.
			</p>

			<div className={'titlePoint'}>
				9.- TERMINACIÓN DEL CONTRATO
			</div>

			<p>
				El CLIENTE está de acuerdo en que ETOMIN se reserva el derecho de denegar, dar por terminado o suspender de manera unilateral el servicio. Acepta que basta con una constancia emitida vía electrónica por ETOMIN respecto a que no cumple los requisitos establecidos en el presente para que se actualice este supuesto.
			</p>

			<p>
				Se podrá suspender o congelar la cuenta o método de pago si se sospecha que existe algún involucramiento con cargos a tarjetas u operaciones con tarjetas de crédito o débito ilegítimas, contrarias a derecho, al presente o a nuestras políticas.
			</p>

			<div className={'subTitlePoint'}>
				9.1 BLPOQUEAR MÉTODO DE PAGO
			</div>

			<p>
				Acepta el CLIENTE que ETOMIN podrá dar de baja inmediata o bloquear cualquier Método de Pago ofrecido como parte del servicio, a solicitud expresa por los terceros involucrados en las operaciones, como la Cámara de Compensación, CNBV, Banca Adquirente y los diversos tipos de tarjetas. Se libera a ETOMIN de cualquier responsabilidad u obligación tendiente a exhibir, comprobar o proveer información o documentos tendientes a comprobar las razones por las que se da de baja cualquier cuenta. Ninguna baja de cuenta decidida por ETOMIN unilateralmente será refutable por ninguna vía administrativa o jurisdiccional.
			</p>

			<div className={'subTitlePoint'}>
				9.2 ESTADO DE CUENTA FINAL
			</div>

			<p>
				ETOMIN realizará un estado de cuenta final en el cual se lleven a cabo todas las compensaciones correspondientes a las comisiones, multas o cualquier otro concepto pendiente de cobrar, mismo que se deberá entregar a los treinta días posteriores a la notificación de terminación por correo electrónico.
			</p>

			<p>
				ETOMIN se compromete a reintegrar la garantía a que se refiere la cláusula anterior a los noventa días de terminado el contrato siempre y cuando no exista reclamación alguna o saldo pendiente de liquidar por parte del CLIENTE, en cuyo caso se autoriza expresamente a ETOMIN para aplicar dicha garantía a los saldos que pudiera tener el CLIENTE pendientes de liquidar a ETOMIN.
			</p>

			<div className={'titlePoint'}>
				10.- RESCISIÓN
			</div>

			<p>
				ETOMIN podrá rescindir este contrato, con derecho a indemnización de daños y perjuicios causados, en caso de incumplimiento de las obligaciones establecidas en el mismo y de forma unilateral bastando para dichos efectos una notificación vía correo electrónico al CLIENTE.
			</p>

			<p>
				El CLIENTE está de acuerdo en que ETOMIN utilice la garantía a que se refiere la cláusula OCTAVA para liquidar el pago de los daños y perjuicios ocasionados y cualquier otro saldo, comisión o multa pendiente de liquidar.
			</p>

			<p>
				EL CLIENTE autoriza a ETOMIN a conservar el depósito en garantía hasta por un lapso de 90 días con la finalidad de asegurar que no existan reclamaciones o cargos pendientes.
			</p>

			<p>
				ETOMIN devolverá el remanente del depósito a los noventa días.
			</p>

			<div className={'titlePoint'}>
				11.- NOTIFICACIONES
			</div>

			<p>
				Las notificaciones que se realicen por correo electrónico tendrán pleno valor para efectos del presente contrato, razón por la cual el CLIENTE ratifica que cualquier notificación realizada en el correo que proporcionó será válida. Por su parte se puede contactar a ETOMIN por medio del correo electrónico: soporte@etomin.com
			</p>

			<div className={'titlePoint'}>
				12.- CESIÓN DE DERECHOS
			</div>

			<p>
				Ninguna de las partes podrá ceder, parcial o totalmente, los derechos y obligaciones derivadas de este convenio sin el previo consentimiento por escrito otorgado por la contraparte.
			</p>

			<div className={'titlePoint'}>
				13.- DEL RECONOCIMIENTO
			</div>

			<p>
				El presente convenio constituye todo lo acordado entre las partes en relación con su objeto y deja sin efecto cualquier otra negociación, obligación o comunicación entre éstas, ya sea verbal o escrita, efectuada con anterioridad al presente.
			</p>

			<div className={'titlePoint'}>
				14.- REGIMEN JURÍDICO
			</div>

			<p>
				El presente contrato tiene carácter mercantil, no existiendo en ningún caso vínculo laboral alguno entre el CLIENTE y el personal de ETOMIN que preste concretamente los Servicios.
			</p>

			<p>
				Toda controversia derivada de este contrato o que guarde relación con él –incluida cualquier cuestión relativa a su existencia, validez o terminación- será resuelta mediante arbitraje DE DERECHO, administrado por el Centro de Arbitraje de México, CAM, de conformidad con su Reglamento de Arbitraje vigente a la fecha de presentación de la solicitud de arbitraje. El Tribunal Arbitral que se designe a tal efecto estará compuesto por un único árbitro experto y el idioma del arbitraje será Español. La sede del arbitraje será la Ciudad de México.
			</p>
        
        </div>
    );

}

export default HeroHome;