import React from "react";
import { Link } from "react-router-dom";
import "assets/styles/home/AcceptCelHome.scss";
import pagos from "assets/img/recibe-pagos.png";

import Title from "components/common/sections/Title";
import Clearfix from "components/common/Clearfix";

const AcceptHome = () => {
  return (
    <div className={'acceptCelWrapper'}>
      <div className={'acceptCel'}>
        <div className={`${'left'} ${'section'} ${'info'}`}>
          <div className="section-content">
            <Title text={"Recibe pagos en cualquier lugar"} position={"left"} />
            <p>
              Con nuestro lector de tarjetas de crédito y débito para teléfonos
              móviles
            </p>
            <div className={'action'}>
              <Link to={"/ecommerce"}>Más información</Link>
            </div>
          </div>
        </div>
        <div className={`${'right'} ${'section'}`}>
          &nbsp;
          <img src={pagos} alt="Pagos" />
        </div>
        <Clearfix />
      </div>
    </div>
  );
};

export default AcceptHome;
