import React, { Fragment } from "react";
import "assets/styles/device/Pays.scss";
import Title from "components/common/sections/Title";
import PaysCard from "components/device/sections/PaysCard";
import reader from "assets/img/reader.png";

const Pays = () => {
  return (
    <Fragment>
      <div className={'paysWrapper'}>
        <div className={'pays'}>
          <div className={'paysContainer'}>
            <Title text="Recibe pagos en cualquier lugar" position="Left" />
            <p className={'paysSubtitle'}>
              Con nuestro lector de tarjetas de crédito y débito para teléfonos
              móviles
            </p>
            <div id="payment-content" className={'paysReaderInfo'}>
              <div className={'paysReader'}>
                <img src={reader} alt="reader" />
              </div>
              <div className={'paysReaderData'}>
                <PaysCard />
              </div>
            </div>
          </div>
          <div className={'paysApp'}>
            <div className={'paysAppItem'}>
              <p className={'paysAppDownload'}> DESCARGA LA APP GRATIS </p>
            </div>
            <div className={'paysAppItem'}>
              <p className={'paysAppSell'}>COBRA EN DONDE SEA</p>
            </div>
            <div className={'paysAppItem'}>
              <p className={'paysAppMovil'}>
                COMPATIBLE CON IOS Y ANDROID
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Pays;
