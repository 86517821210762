import React from "react";

import "assets/styles/home/SolutionHome.scss";

import TitleLeft from "../../common/sections/TitleLeft";
import Card from "./SolutionHomeCard";

import card from "assets/img/card.png";
import profile from "assets/img/profile.png";
import calculator from "assets/img/calculator.png";
import lock from "assets/img/lock.png";
import map from "assets/img/map.png";
import wallet from "assets/img/wallet.png";

const SolutionHome = () => {
  return (
    <div className={'solutionHomeWrapper'}>
      <div className={'solutionHome'}>
        <TitleLeft>
          La mejor solución para aumentar las ventas de su negocio
        </TitleLeft>
        <div className={'solutionHomeCardContainer'}>
          <Card
            title={"Vende más"}
            text={"Ofrece diferentes formas de pago e incrementa tus ventas"}
            image={card}
            imageWidth="55px"
          />
          <Card
            title={"Registro sencillo"}
            text={"Regístrate fácilmente, sin costo y sin plazo forzoso"}
            image={profile}
            imageWidth="55px"
          />
          <Card
            title={"Una sola terminal"}
            text={"Acepta Visa, MasterCard, Amex y  meses sin intereses"}
            image={calculator}
            imageWidth="33px"
          />
          <Card
            title={"Transacciones seguras"}
            text={
              "Contamos con motores antifraudes y certificaciones PCI, EMV, CNIBV, Banxico, Kount y Digicert."
            }
            image={lock}
            imageWidth="43px"
          />
          <Card
            title={"Cobra en cualquier lugar"}
            text={"Ofrece diferentes formas de pago e incrementa tus ventas"}
            image={map}
            imageWidth="45px"
          />
          <Card
            title={"Tu dinero siempre disponible"}
            text={"Transfiere tu dinero a tus cuentas o a terceros rápidamente desde el portal"}
            image={wallet}
            imageWidth="48px"
          />
        </div>
        <div style={{ clear: "both" }} />
      </div>
    </div>
  );
};

export default SolutionHome;
