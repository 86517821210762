import React from "react";

import "assets/styles/sections/TitleLeft.scss";

const TitleLeft = ({ customStyles, children }) => {
  return (
    <h2 className={'titleLeft'} style={customStyles}>
      {children}
    </h2>
  );
};

export default TitleLeft;
