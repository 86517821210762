import React from "react";
import "assets/styles/home/Selects.scss";
import Select from "components/home/sections/Select";

const Selects = ({ data }) => {
  const renderSelects = data => {
    return data.map((select, index) => {
      return <Select key={index} data={select} />;
    });
  };

  
  return (
    <div className={'simulatorDataCard'}>
      {renderSelects(data)}
    </div>
  );

}

export default Selects;
