import React from "react";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import "assets/styles/style.scss";

import HomeScreen from "components/home";
import EcommerceScreen from "components/ecommerce";
import DeviceScreen from "components/device";
import TermsAndConditions from "components/common/terminsAndConditions";
import Privacy from "components/common/privacy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "ecommerce",
    element: <EcommerceScreen />,
  },
  {
    path: "device",
    element: <DeviceScreen />,
  },
  {
    path: "aviso-de-privacidad",
    element: <Privacy />,
  },
  {
    path: "terminos-y-condiciones",
    element: <TermsAndConditions />,
  },
]);

const Routes = () => {
  return (
    <div className={'root'}>
      <RouterProvider router={router} />
    </div>
  )
}

export default Routes;
