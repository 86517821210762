import React from "react";

import Clearfix from "components/common/Clearfix";
import {
  PRIVACY_LINK,
  TERMS_LINK,
  VENDERS_LINK,
  FB_LINK,
  TWITTER_LINK
} from "constants/etomin";

import "assets/styles/layout/Footer.scss";

import fb from "assets/img/etomin-facebook.svg";
import twitter from "assets/img/etomin-twitter.svg";
import logo from "assets/img/logo-white.svg";
import venders from "assets/img/venders-logo.svg";

const Footer = () => {
  return (
    <div className={'footerWrapper'}>
      <div className={'footer'}>
        <div className={'footerSection'}>
          <div className={`${'left'}`}>
            <img className={'logo'} src={logo} alt="etomin" />
            <p className={'know'}>
              Solución de pagos de Grupo Venders
            </p>
            <ul className={'solutions'}>
              <li>
                <a href={VENDERS_LINK} target={"__blank"}>
                  <img src={venders} alt={"logo"} />
                </a>
              </li>
              {/* <li>
                <a href={IKNIU_LINK} target={"__blank"}>
                  <img src={ikniu} alt={"logo"} />
                </a>
              </li>
              <li>
                <a href={ANKOO_LINK} target={"__blank"}>
                  <img src={ankoo} alt={"logo"} />
                </a>
              </li>
              <li className={'pakke'}>
                <a href={PAKKE_LINK} target={"__blank"}>
                  <img src={pakke} alt={"logo"} />
                </a>
              </li> */}
            </ul>
            <ul className={'followUs'}>
              <li>Síguenos en</li>
              <li>
                <a href={FB_LINK} target={"__blank"}>
                  <img className="fb" src={fb} alt="etomin fb" />
                </a>
              </li>
              <li>
                <a href={TWITTER_LINK} target={"__blank"}>
                  <img className="twitter" src={twitter} alt="etomin twitter" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={'footerSection'}>
          {/* <div className={'formWrapper'}>
            <h3>Suscríbete</h3>
            <p>Recibe las novedades de nuestras soluciones</p>
            <form
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              method="post"
              action="https://etomin.us15.list-manage.com/subscribe/post?u=7615883841e2879cfa4167f10&amp;id=cab77a91a3"
            >
              <input
                className={'inputFooter'}
                placeholder="Email"
                id="mce-EMAIL"
                type="email"
                name="email"
                required
              />
              <label htmlFor="accept">
                <input
                  className={'checkbox'}
                  type="checkbox"
                  id="news-checkbox"
                  name="news-checkbox"
                  required
                />
                &nbsp;&nbsp;&nbsp;Acepto las políticas de privacidad del sitio
              </label>
              <button id="mc-embedded-subscribe" name="subscribe" type="submit">
                Suscribirse
              </button>
              <Clearfix />
            </form>
          </div> */}
        </div>
        <Clearfix />
      </div>

      <div className={'subfooterWrapper'}>
        <div className={'subfooter'}>
          <ul className={'left'}>
            <li>
              <a href={PRIVACY_LINK}>
                Aviso de privacidad
              </a>
            </li>
            <li>
              <a href={TERMS_LINK}>
                Términos y Condiciones
              </a>
            </li>
          </ul>
          <ul className={`${'right'} ${'smHide'}`}>
            <li>&copy; {new Date().getFullYear()} Venders. Todos los derechos reservados</li>
          </ul>
          <Clearfix />
        </div>
      </div>
    </div>
  );
};

export default Footer;
