import React, { Fragment } from "react";

import "assets/styles/ecommerce/Commissions.scss";

const CommissionsCard = ({ paymentMethod, percentage, plusText, availability }) => {

  const renderCardImages = () => {
    return paymentMethod.map((image, index) => {
      return (
        <Fragment key={index}>
          <img src={image} alt={image + index} />
        </Fragment>
      );
    });
  };

  
    return (
      <div className={'commissionsEcommerceCardWrapper'}>
        <div className={'commissionsEcommerceCard'}>
          <div className={'cardHeader'}>{renderCardImages()}</div>
          <hr />
          <div className={'cardBody'}>
            <p>
              {percentage}
              <span>%</span>
            </p>
            <p className={'plus'}>+${plusText} MXN</p>
          </div>
          <div className={'cardFooter'}>
            <p>
              Disponibilidad: <span>{availability}</span>
            </p>
          </div>
          <div style={{ clear: "both" }} />
        </div>
      </div>
    );

}

export default CommissionsCard;
