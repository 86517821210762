import React from "react";
import "assets/styles/home/QuestionsHomeCard.scss";

import Clearfix from "components/common/Clearfix";

const QuestionsHomeCard = ({ image, imageWidth = "55px", title, text }) => {
  return (
    <div className={'questionsHomeCardWrapper'}>
      <div className={'questionsHomeCard'}>
        <div className={'questionsHomeCardIconWrapper'}>
          <div className={'questionsHomeCardIcon'}>
            <img src={image} alt="solución" style={{ width: imageWidth }} />
          </div>
        </div>
        <div className={'questionsHomeCardContent'}>
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
        <Clearfix />
      </div>
    </div>
  );
};

export default QuestionsHomeCard;
