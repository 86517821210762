import React from "react";
import "assets/styles/ecommerce/RegisterEcommerceCard.scss";

import Clearfix from "components/common/Clearfix";

const RegisterEcommerceCard = ({
  image,
  imageWidth = "55px",
  title,
  text,
  br,
  bb,
  bt
}) => {
  return (
    <div
      className={`${'registerEcommerceCardWrapper'} ${br &&
        'br'} ${bb && 'bb'} ${bt && 'bt'}`}
    >
      <div className={'registerEcommerceCard'}>
        <div className={'registerEcommerceCardIconWrapper'}>
          <div className={'registerEcommerceCardIcon'}>
            <img src={image} alt="solución" style={{ width: imageWidth }} />
          </div>
        </div>
        <div className={'registerEcommerceCardContent'}>
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
        <Clearfix />
      </div>
    </div>
  );
};

export default RegisterEcommerceCard;
