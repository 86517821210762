import React, { Component, Fragment } from "react";

import Header from "../common/layout/Header";
import SubHeader from "../common/layout/SubHeader";
import Hero from "components/device/sections/Hero";
import Pays from "components/device/sections/Pays";
// import Simulator from "components/device/sections/Simulator";
import Commissions from "components/device/sections/Commissions";
import Transactions from "components/device/sections/Transactions";
import Footer from "components/common/layout/Footer";

const DeviceScreen = () => {
  // componentDidMount() {
  //   (function(w, d, s, i, p) {
  //     var f = d.getElementsByTagName(s)[0],
  //       j = d.createElement(s);
  //     j.id = i;
  //     j.async = true;
  //     j.src =
  //       "https://api.etomin.com/API/v1.0/widget/" + p + "/etomin.button.js";
  //     f.parentNode.insertBefore(j, f);
  //   })(
  //     window,
  //     document,
  //     "script",
  //     "etomin-button",
  //     "PB_key_8b62398c54ea30d59c6c68247adbaa8c"
  //   );
  // }

  return (
    <Fragment>
      <Header />
      <SubHeader />
      <Hero />
      <Pays />
      <Commissions />
      <div style={{ clear: "both" }} />
      <Transactions />
      {/* 
      <Simulator />
    */}
    <Footer />
    </Fragment>
  );

}

export default DeviceScreen;
