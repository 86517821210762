import React from "react";
import "assets/styles/device/Commissions.scss";
import Title from "components/common/sections/Title";
import Card from "components/ecommerce/sections/CommissionsCard";
import { SIMULATOR_CARDS } from "constants/etomin";

const Commissions = () => {
  const renderCards = () => {
    return SIMULATOR_CARDS.map((card, index) => {
      return (
        <Card
          key={index}
          percentage={card.percentage}
          plusText={card.plus}
          availability={card.availability}
          paymentMethod={card.paymentMethod}
        />
      );
    });
  };


    return (
      <div className={"commissionsDeviceWrapper"}>
        <div className={"commissionsDevice"}>
          <Title text="Comisiones" position="Center" />
          <div className={"commissionsDeviceCardContainer"}>
            {renderCards()}
          </div>
          <p className={'commissionsDisclaimer textCenter'}>
            Oferta sujeta cambios sin previo aviso
          </p>
        </div>
      </div>
    );

}

export default Commissions;
