import React, { useState } from "react";

import "assets/styles/TermsAndConditions.scss";

import background from "assets/img/bg-pattern.jpg";

import TitleLeft from "components/common/sections/TitleLeft";
import Clearfix from "components/common/Clearfix";

const HeroHome = () => {
    return (
			<div className={'TermsAndConditionsContainer'}>

				<p>
					Grupo Venders México S.A. DE C.V. (en adelante “Etomin” o “Nosotros”), es la responsable del uso y protección de tus datos personales.
				</p>

				<p>
					En Etomin nuestro negocio es la confianza, por eso buscamos ser transparentes sobre el tratamiento que le damos a los datos personales de las personas que accedan a nuestro sitio web y de aquellas que utilicen o pretendan utilizar nuestros servicios. Este aviso de privacidad está dirigido a nuestros clientes, prospectos de clientes, a los usuarios finales (quienes realizan pagos a nuestros clientes a través de Etomin) así como a los visitantes del sitio https://www.etomin.com/ y relacionados.
				</p>

				<div className={'titlePoint'}>
					I. ¿Para qué utilizamos tus datos?
				</div>
				<p>
					Finalidades primarias
				</p>
				<p>
					Si eres un cliente nuestro o estás en proceso de convertirte en uno de nuestros clientes, tus datos personales los utilizamos para las siguientes finalidades que son necesarias para brindarte nuestros servicios:
				</p>

				<p>
					Para registrarte en nuestra plataforma informática.
				</p>
				<p>
					Para confirmar tu identidad.
				</p>
				<p>
					Para validar tu información.
				</p>
				<p>
					Para realizar análisis, clasificaciones y segmentaciones a efecto de determinar los productos y/o servicios que más se ajusten a tus necesidades.
				</p>
				<p>
					Para comunicarnos contigo en relación con los productos y/o servicios que estás interesado en contratar o que podemos ofrecerte.
				</p>
				<p>
					Para que contrates nuestros productos y/o servicios.
				</p>
				<p>
					Para activarte como cliente y activar una cuenta asociada a ti o a la persona moral que contrata nuestros servicios.
				</p>
				<p>
					Para comunicarnos contigo en relación con los productos y/o servicios que tienes contratados con nosotros.
				</p>
				<p>
					Para alimentar, desarrollar y optimizar nuestros sistemas antifraude, cooperar con autoridades competentes y con otros participantes en las redes de medios de disposición en la investigación de posibles actividades ilícitas.
				</p>
				<p>
					Para resolver controversias o disputas relacionadas con los Servicios,
				</p>
				<p>
					Si eres un usuario final (realizas un pago a través de Etomin) tus datos personales los utilizamos para las siguientes finalidades que son necesarias para la prestación de nuestros servicios:
				</p>
				<p>
					Para confirmar tu identidad.
				</p>
				<p>
					Para validar tu información.
				</p>
				<p>
					Para alimentar, desarrollar y optimizar nuestros sistemas antifraude, cooperar con autoridades competentes y con otros participantes en las redes de medios de disposición en la investigación de posibles actividades ilícitas.
				</p>
				<p>
					Para procesar los pagos que realices a alguno de nuestros clientes.
				</p>
				<p>
					Para brindarte atención o canalizarte con quien pueda brindarte atención en relación con tus pagos procesados a través de Etomin.
				</p>
				<p>
					Para brindarte distintas alternativas para el pago de productos y servicios, incluyendo esquemas de compra para aplazar el pago ("Pago a Plazos").
				</p>
				<p>
					Finalidades secundarias
				</p>
				<p>
					De manera adicional, los datos de nuestros clientes, prospectos de clientes y usuarios finales los utilizamos para las siguientes finalidades adicionales:
				</p>

				<p>
					Para fines mercadotécnicos, publicitarios o de prospección comercial, incluyendo publicidad y promociones propios o de nuestros socios comerciales.
				</p>
				<p>
					Para evaluar la calidad de nuestro proceso de registro de clientes.
				</p>
				<p>
					Para realizar análisis, clasificaciones y segmentaciones a efecto de determinar los productos y/o servicios propios o de terceros que más se ajusten a tus necesidades.
				</p>
				<p>
					En caso de que no desees que tus datos personales sean tratados para estas finalidades secundarias o adicionales, puedes manifestar tu negativa al respecto utilizando este formulario.
				</p>
				<p>
					Tu negativa al tratamiento de tus datos personales para las finalidades adicionales o secundarias no podrá ser motivo para que te neguemos nuestros servicios y productos.
				</p>
				<div className={'titlePoint'}>
					II. ¿Qué datos personales recabamos?
				</div>
				<p>
					Para llevar a cabo las finalidades descritas en este aviso de privacidad, recabamos las siguientes categorías de datos personales de nuestros clientes y/o prospectos:
				</p>

				<p>
					Datos de identificación
				</p>
				<p>
					Datos de contacto
				</p>
				<p>
					Datos patrimoniales o financieros
				</p>
				<p>
					Datos comerciales
				</p>
				<p>
					Datos de tus clientes y/o usuarios finales, incluyendo datos financieros y patrimoniales que utilizamos para el procesamiento de los pagos electrónico
				</p>
				<p>
					De los usuarios finales recabamos las siguientes categorías de datos personales:
				</p>
				<p>
					Datos de identificación
				</p>
				<p>
					Datos de contacto
				</p>
				<p>
					Datos patrimoniales o financieros
				</p>
				<p>
					Datos comerciales
				</p>
				<p>
					En Etomin no recolectamos ni tratamos datos personales sensibles.
				</p>

				<p>
					De conformidad con la LFPDPPP para el tratamiento de tus datos personales financieros o patrimoniales requerimos tu consentimiento expreso y por escrito, por lo que al aceptar este aviso de privacidad por medios electrónicos, también aceptas el tratamiento de tus datos financieros o patrimoniales.
				</p>

				<div className={'titlePoint'}>
					III. ¿Con quién compartimos tus datos y para qué?
				</div>
				<p>
					Para la prestación de nuestros servicios y a lo largo de nuestra relación comercial contigo podremos transmitir tus datos personales con las siguientes categorías de terceros y para las finalidades que señalamos a continuación:
				</p>

				<p>
					Sociedades subsidiarias, filiales o que forman parte de nuestro grupo corporativo con la finalidad de consolidar la información corporativa y comercial y brindar los servicios que ofrecemos de acuerdo a las necesidades comerciales de Etomin. Por ejemplo, algunos de los empleados del grupo corporativo trabajan para sociedades constituidas fuera del territorio nacional.
				</p>
				<p>
					Proveedores de servicios de tecnología, almacenamiento y bases de datos, analítica, seguridad de la información, soporte técnico, call center, mercadotecnia, sistemas antifraude, consultoría, jurídicos, así como cualquier otro servicio que resulte necesario para el desempeño de nuestros servicios. Estos terceros asumen nuestras mismas obligaciones respecto a la protección de tus datos personales. Nuestros proveedores son evaluados por nuestro departamento de seguridad de la información, nos aseguramos de que contractualmente: (i) sólo utilicen los datos personales conforme a nuestras instrucciones, y (ii) cuenten con las medidas de seguridad de la información adecuadas en relación con el servicio que nos brindan;*
				</p>
				<p>
					Instituciones financieras y otras entidades participantes en la red de medios de disposición para el procesamiento de los pagos
				</p>
				<p>
					Nuestros socios comerciales o aliados estratégicos, ya sea porque resulta necesaria su participación en la prestación de nuestros servicios o porque consideramos que podrían ofrecerte productos o servicios de tu interés;*
				</p>
				<p>
					Autoridades, organismos o entidades gubernamentales; en cumplimiento a las obligaciones contempladas en la legislación aplicable y/o en cumplimiento de requerimientos efectuados por las mismas, principalmente en materia de prevención de lavado de dinero;
				</p>
				<p>
					Empresas de cobranza, para la recuperación de adeudos y su cobro judicial o extrajudicial;
				</p>
				<p>
					Inversionistas o posibles inversionistas con la finalidad de que evalúen nuestras relaciones comerciales y la pertinencia de invertir en Etomin.*
				</p>
				<p>
					Para las transferencias indicadas con un asterisco (*) requerimos obtener tu consentimiento, al aceptar este aviso de privacidad entendemos que también nos otorgas tu consentimiento sobre estas transferencias.
				</p>

				<p>
					Además, si eres un usuario final, te informamos que tus datos personales también los compartimos con nuestros clientes, a excepción de aquellos datos financieros que no podamos compartir por cuestiones de cumplimiento con la certificación PCI DSS (relativa a la seguridad de los datos en la industria de pagos con tarjeta).
				</p>

				<div className={'titlePoint'}>
					IV. ¿Cómo puedo ejercer mis derechos arco y/o revocar mi consentimiento sobre el tratamiento de mis datos personales?
				</div>
				<p>
					Te invitamos a ejercer tus derechos de acceso, rectificación, cancelación u oposición (“Derechos ARCO”) o a revocar tu consentimiento sobre el tratamiento de tus datos personales a través de este formulario.
				</p>

				<p>
					En caso de que tu solicitud no cumpla con alguno de los requisitos, te contactaremos para solicitarte información adicional dentro de los cinco días hábiles siguientes a tu solicitud. En caso de no recibir esta información dentro de los siguientes diez días hábiles, tendremos que desechar tu solicitud. Daremos respuesta a tu solicitud dentro de los veinte días hábiles siguientes a haber recibido la misma sin ningún defecto y con la información necesaria para procesarla.
				</p>

				<div className={'titlePoint'}>
					V. ¿Qué mecanismos adicionales me permiten limitar el uso o divulgación de mis datos personales?
				</div>
				<p>
					En Etomin, tenemos habilitado un listado de exclusión de correos electrónicos, en caso de que no desees seguir recibiendo correos electrónicos con fines publicitarios de parte nuestra, podrás deshabilitar tu suscripción en cualquier momento a través del link que encontrarás al final de cada correo.
				</p>

				<p>
					Adicionalmente, puedes limitar el uso o divulgación de tus datos personales inscribiéndote en el Registro Público para Evitar Publicidad, que está a cargo de la Procuraduría Federal del Consumidor. Esto evitará que  tus datos personales sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para mayor información sobre este registro, puedes consultar el portal de internet de la PROFECO, o ponerte en contacto directo con ésta.
				</p>

				<p>
					VI. Cookies, web beacons y otras tecnologías de rastreo.
				</p>
				<p>
					En nuestra página de Internet utilizamos cookies, web beacons y otras tecnologías a través de las cuales nos es posible monitorear tu comportamiento como usuario de internet, brindarte un mejor servicio y experiencia de usuario al navegar en nuestra página, y ofrecerte nuevos productos y servicios basados en tus preferencias.
				</p>

				<p>
					Algunos de los datos personales que obtenemos con estas tecnologías son: horario de navegación, tiempo de navegación en nuestra página de internet, secciones consultadas, y páginas de internet accedidas previo a la nuestra.
				</p>
        
			</div>
    );

}

export default HeroHome;