import React, { Fragment } from "react";

import Header from "../common/layout/Header";
import SubHeader from "../common/layout/SubHeader";
import Hero from "components/ecommerce/sections/HeroEcommerce";
import Register from "components/ecommerce/sections/RegisterEcommerce";
import Commissions from "components/ecommerce/sections/Commissions";
import Simulator from "components/ecommerce/sections/Simulator";
// import Security from "components/ecommerce/sections/SecurityEcommerce";
// import Form from "components/ecommerce/sections/FormEcommerce";
import Footer from "components/common/layout/Footer";

const EcommerceScreen = () => {
  // componentDidMount() {
  //   (function(w, d, s, i, p) {
  //     var f = d.getElementsByTagName(s)[0],
  //       j = d.createElement(s);
  //     j.id = i;
  //     j.async = true;
  //     j.src =
  //       "https://api.etomin.com/API/v1.0/widget/" + p + "/etomin.button.js";
  //     f.parentNode.insertBefore(j, f);
  //   })(
  //     window,
  //     document,
  //     "script",
  //     "etomin-button",
  //     "PB_key_8b62398c54ea30d59c6c68247adbaa8c"
  //   );
  // }

    return (
      <Fragment>
        <Header />
        <SubHeader />
        <Hero />
        <Register />
        <Commissions />
        <Simulator />
        {/* 
        // <Form />
        // <Security />
      */}
        <Footer /> 
      </Fragment>
    );

}

export default EcommerceScreen;
