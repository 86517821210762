import React from "react";

import "assets/styles/layout/SubHeader.scss";
// import phone from "assets/img/etomin-telefono.svg";
import fb from "assets/img/etomin-facebook.svg";
import twitter from "assets/img/etomin-twitter.svg";

import {
  FB_LINK,
  TWITTER_LINK,
  // TELEPHONE,
  // HELP_LINK,
  // DOCS_LINK,
  // BLOG_LINK
} from "constants/etomin";

const SubHeader = ({ inverse = false }) => {
  return (
    <div className={'subheaderWrapper'}>
      <header className={'subheader'}>
        <nav className={'left'}>
          <ul className={'subheaderList'}>
            {/* <li className={`${'borderRight'}`}>
              <a className={'first'} href={`tel:${TELEPHONE}`}>
                <img src={phone} alt="etomin phone" />
                +52 55 2629 9851
              </a>
            </li> */}
            <li className={`${'xsHide'}`}>Síguenos en</li>
            <li className={`${'xsHide'}`}>
              <a href={FB_LINK} target={"__blank"}>
                <img src={fb} alt="facebook" />
              </a>
            </li>
            <li className={`${'xsHide'}`}>
              <a href={TWITTER_LINK} target={"__blank"}>
                <img src={twitter} alt="twitter" />
              </a>
            </li>
          </ul>
        </nav>

        <nav className={`${'rightLinksSmall'}`}>
          <ul className={'subheaderList'}>
            <li>Síguenos en</li>
            <li>
              <a href={FB_LINK} target={"__blank"}>
                <img src={fb} alt="facebook" />
              </a>
              <a href={TWITTER_LINK} target={"__blank"}>
                <img src={twitter} alt="twitter" />
              </a>
            </li>
          </ul>
        </nav>

        <nav className={`${'rightLinks'} ${'xsHide'}`}>
          <ul className={'subheaderList'}>
            <li
              className={`${inverse ? 'inverseLink' : ""} ${
                inverse ? 'borderRightInverse' : ""
              }`}
            >
              <a
                className={inverse ? 'inverseLink' : ""}
                href={'/#'}
                // href={HELP_LINK}
                // target={"__blank"}
              >
                Ayuda
              </a>
            </li>
            <li
              className={`${inverse ? 'inverseLink' : ""} ${
                inverse ? 'borderRightInverse' : ""
              }`}
            >
              <a
                className={inverse ? 'inverseLink' : ""}
                href={'https://developers.etomin.com'}
                // href={DOCS_LINK}
                // target={"__blank"}
              >
                Documentación
              </a>
            </li>
            {/* <li>
              <a
                className={`${inverse ? 'inverseLink' : ""} ${
                  'last'
                }`}
                href={BLOG_LINK}
                // target={"__blank"}
              >
                Blog
              </a>
            </li> */}
          </ul>
        </nav>
        <div style={{ clear: "both" }} />
      </header>
    </div>
  );
};

export default SubHeader;
