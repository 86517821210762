import React from "react";

import "assets/styles/home/SolutionHomeCard.scss";

const SolutionHomeCard = ({ image, imageWidth = "55px", title, text }) => {
  return (
    <div className={'solutionHomeCardWrapper'}>
      <div className={'solutionHomeCard'}>
        <div className={'solutionHomeCardIconWrapper'}>
          <div className={'solutionHomeCardIcon'}>
            <img src={image} alt="solución" style={{ width: imageWidth }} />
          </div>
        </div>
        <div className={'solutionHomeCardContent'}>
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
        <div style={{ clear: "both" }} />
      </div>
    </div>
  );
};

export default SolutionHomeCard;
