import React, { useState } from "react";
import ModalVideo from "react-modal-video";

import "assets/styles/ecommerce/HeroEcommerce.scss";
import "assets/styles/HeroCommon.scss";

import background from "assets/img/bg-pattern.jpg";
import computer from "assets/img/laptop.png";
import video from "assets/img/etomin-video.svg";

import { REGISTER_LINK, VIDEO_ID } from "constants/etomin";

import Clearfix from "components/common/Clearfix";
import HeroFooter from "components/common/HeroFooter";

const HeroHome = () => {
  const [showModal, setShowModal] = useState(false);


    return (
      <>
      <div className={'heroEcommerceWrapper'}>
        <img
          className={'heroEcommerceBackground'}
          src={background}
          alt="Background"
        />
        <div className={'heroEcommerce'}>
          <div className={'heroEcommerceContent'}>
            <div className={'heroEcommerceContentLeft'}>
              <div>
                <span className={'heroSubtitle'}>
                  {"Lleva tu negocio a más personas"}
                </span>
                <h1 className={'heroTitle'}>
                  {"Acepta los pagos de tus"}
                  <br />
                  {"clientes en tu página web"}
                </h1>
                <div className={'heroButtons'}>
                  <a
                    className={`${'heroButton'} ${
                      'heroButtonGreen'
                    }`}
                    href={REGISTER_LINK}
                    target={"__blank"}
                  >
                    Registrarse
                  </a>
                  <a
                    className={'heroButton'}
                    onClick={() => setShowModal(true)}
                    href={"#video"}
                  >
                    <img src={video} alt="video" />
                    &nbsp;&nbsp;&nbsp; VER VIDEO
                  </a>
                  <Clearfix />
                </div>
              </div>
            </div>
            <div className={'heroEcommerceContentRight'}>
              <div className={'heroEcommerceContentImage'}>
                <img src={computer} alt="desktop" />
              </div>
            </div>
            <HeroFooter center />
            <Clearfix />
          </div>
        </div>
        
      </div>
      {showModal && <div className={'heroEcommerceModalContainer'}>
        <div className={'heroEcommerceModal'}>
          <div className={'heroEcommerceModalClose'} onClick={() => setShowModal(false)}>
            ✖️
          </div>
          <div className={'heroEcommerceModalFrame'}>
            <ModalVideo
              channel="youtube"
              youtube={{ mute: false, autoplay: true }}
              isOpen={true}
              videoId={VIDEO_ID}
            />
          </div>
        </div>
      </div>}
      </>
    );

}

export default HeroHome;