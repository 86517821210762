import amex from "assets/img/amex-color.png";
import visa from "assets/img/visa-color.png";
import mastercard from "assets/img/mastercard-colo.png";
import carnet from "assets/img/carnet-color.png";
import oxxo from "assets/img/oxxo-color.png";
import spei from "assets/img/spei-color.png";

export const LOGIN_LINK = "https://panel.etomin.com/#login";
export const REGISTER_LINK = "https://panel.etomin.com/#register";
export const FB_LINK = "https://www.facebook.com/etominMx/";
export const TWITTER_LINK = "https://twitter.com/etominMx/";
export const TELEPHONE = "+52 55 2629 9851";
export const HELP_LINK = "http://help.etomin.com/";
export const DOCS_LINK = "http://docs.etomin.com/";
export const BLOG_LINK = "http://blog.etomin.com/";
export const MORE_INFO = "http://help.etomin.com/comisiones/";
export const VIDEO_ID = "95bpq5FYaQ0";
export const SIMULATOR_CARDS = [
  {
    percentage: 3.6,
    plus: 3.5,
    plusText: "3.50",
    availability: "2 días hábiles",
    paymentMethod: [mastercard, visa, carnet],
    selectText: "Visa, Master Card, Carnet"
  },
  {
    percentage: 4.5,
    plus: 3.5,
    plusText: "3.50",
    availability: "4 días hábiles",
    paymentMethod: [amex],
    selectText: "Amex"
  }
];
export const SIMULATOR_CARDS_ECOMMERCE = [
  {
    percentage: 3.6,
    plus: 3.5,
    plusText: "3.50",
    availability: "2 días hábiles",
    paymentMethod: [mastercard, visa, carnet],
    selectText: "Visa, Master Card, Carnet"
  },
  {
    percentage: 4.5,
    plus: 3.5,
    plusText: "3.50",
    availability: "4 días hábiles",
    paymentMethod: [amex],
    selectText: "Amex"
  },
  {
    percentage: 0,
    plus: 4.0,
    plusText: "4.00",
    availability: "8 días hábiles",
    paymentMethod: [oxxo],
    selectText: "Oxxo"
  },
  {
    percentage: 0,
    plus: 8.0,
    plusText: "8.00",
    availability: "1 día hábil",
    paymentMethod: [spei],
    selectText: "Spei"
  }
];

export const SIMPLE_PAYMENT_SELECT = [
  { months: 1, percentage: 0, text: "Un solo pago" }
];
export const MONTHLY_PAYMENT_SELECT = [
  { months: 1, percentage: 3.6, text: "Un solo pago" },
  { months: 3, percentage: 4.8, text: "3 meses (4.8% + IVA)" },
  { months: 6, percentage: 7.8, text: "6 meses (7.8% + IVA)" },
  { months: 9, percentage: 10.8, text: "9 meses (10.8% + IVA)" },
  { months: 12, percentage: 13.8, text: "12 meses (13.8% + IVA)" }
];

export const RED_CARD_SELECT = [
  [
    {
      title: "Plan de Pago",
      options: ["Mensual", "Trimestral", "Semestral", "Anual"]
    },
    {
      title: "Intentos",
      options: ["1 intento", "3 intentos", "6 intentos"]
    },
    {
      title: "Al agotar",
      options: ["Mantener suscripción", "Suspender suscripción"]
    }
  ]
];

export const ORANGE_CARD_SELECT = [
  [
    {
      title: "Plan de Pago",
      options: ["Diario", "Semanal", "Mensual"]
    },
    {
      title: "Plan de Pago",
      options: ["Diario", "Semanal", "Mensual"]
    },
    {
      title: "Plan de Pago",
      options: ["Diario", "Semanal", "Mensual"]
    }
  ]
];
export const GREEN_CARD_SELECT = [
  [
    {
      title: "Plan de Pago",
      options: ["Diario", "Semanal", "Mensual"]
    },
    {
      title: "Plan de Pago",
      options: ["Diario", "Semanal", "Mensual"]
    },
    {
      title: "Plan de Pago",
      options: ["Diario", "Semanal", "Mensual"]
    }
  ]
];

export const GOOGLE_MAPS_KEY = "AIzaSyAu-AxlGUngOABcs5Cd5oScIkWnIMg3ogA";
export const PRIVACY_LINK = "/aviso-de-privacidad";
export const TERMS_LINK = "/terminos-y-condiciones";
export const VENDERS_LINK = "http://www.venders.mx/";
export const IKNIU_LINK = "http://www.ikniu.mx/";
export const ANKOO_LINK = "http://www.ankoo.mx/";
export const PAKKE_LINK = "http://www.pakke.mx/";
