import React, { useState } from "react";

import "assets/styles/TermsAndConditions.scss";

import background from "assets/img/bg-pattern.jpg";

import Clearfix from "components/common/Clearfix";

const HeroHome = () => {
    return (
      <div className={'heroEcommerceWrapper'}>
        <img
          className={'heroEcommerceBackground'}
          src={background}
          alt="Background"
        />
        <div className={'heroEcommerce'}>
          <div className={'heroEcommerceContent'}>
            <div className={'heroEcommerceContentLeft'}>
              <div>
                <h1 className={'TermsAndConditionsTitle'}>
                  {"AVISO DE PRIVACIDAD"}
                </h1>
              </div>
            </div>
            <Clearfix />
          </div>
        </div>
        
      </div>
    );

}

export default HeroHome;