import React from "react";

import "assets/styles/sections/Title.scss";

const Title = ({position, text}) => {

  return (
    <div className={`text${position}`}>
      <h2 className={'text'}>{text}</h2>
      <hr className={`hr${position}`} />
    </div>
  );

}

export default Title;
