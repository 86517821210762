import React, { Fragment, useEffect, useState } from "react";
import Cleave from "cleave.js/react";

import "assets/styles/ecommerce/Simulator.scss";

import Title from "components/common/sections/Title";

// import { MORE_INFO } from "constants/etomin";

import {
  SIMPLE_PAYMENT_SELECT,
  MONTHLY_PAYMENT_SELECT,
  SIMULATOR_CARDS_ECOMMERCE
} from "constants/etomin";

const Simulator = () => {
  
  const [values, setValues] = useState({
    paymentMethod: "",
    mount: "",
    monthlyPayment: "",
    total: "0"
  });

  const valueFromEvent = e => {
    let target, value;
    if (e._isAMomentObject) {
      target = e.target;
      value = e.toISOString();

      return value;
    }

    target = e.target;
    value = target.type === "checkbox" ? target.checked : target.value;

    return value;
  };

  const totalValue = async () => {
    if (values.monthlyPayment === "") return;

    let mount, monthlyPayment, paymentMethod, value;

    mount = isNaN(
      parseFloat(parseFloat(values.mount.replace(/,/gi, "")))
    )
      ? 0
      : parseFloat(parseFloat(values.mount.replace(/,/gi, "")));

    monthlyPayment = MONTHLY_PAYMENT_SELECT[values.monthlyPayment];
    paymentMethod = SIMULATOR_CARDS_ECOMMERCE[values.paymentMethod];

    if (parseInt(values.paymentMethod) === 1 || parseInt(values.monthlyPayment) === 0) {
      value =
      mount > 0
        ? mount - ((mount * (paymentMethod.percentage / 100) * 1.16) + paymentMethod.plus)
        : 0;
    } else {
      value =
      mount > 0 &&
      values.paymentMethod !== ""
        ? mount - ( (mount * (monthlyPayment.percentage / 100) * 1.16) + paymentMethod.plus )
        : 0;
    }

    value = (Math.round(value * 100) / 100).toLocaleString();

    setValues({
      ...values, total: value
    });
  };

  const handleValueChange = async e => {
    setValues({
      ...values, [e.target.name]: valueFromEvent(e)
    });
  };

  const renderpaymentMethodSelect = () => {
    return SIMULATOR_CARDS_ECOMMERCE.map((option, index) => {
      return (
        <option key={index} value={index}>
          {option.selectText}
        </option>
      );
    });
  };
  const renderMonthlyPaymentSelect = () => {
    const paymentMethod = SIMULATOR_CARDS_ECOMMERCE[values.paymentMethod];

    if (paymentMethod && paymentMethod.percentage === 0) return SIMPLE_PAYMENT_SELECT.map((option, index) => {
      return (
        <option value={index} key={index}>
          {option.text}
        </option>
      );
    });

    return MONTHLY_PAYMENT_SELECT.map((option, index) => {
      return (
        <option value={index} key={index}>
          {option.text}
        </option>
      );
    });
  };

  useEffect(() => {
    totalValue();
  }, [values.monthlyPayment, values.paymentMethod, values.mount]);

    return (
      <Fragment>
        <div className={'simulator'}>
          <div className={'simulatorContainer'}>
            <Title text="Simulador de ventas" position="Left" />
            <p className={'simulatorSubtitle'}>
              Calcula el monto a recibir de tus ventas
            </p>
            <div className={'simulatorPays'}>
              <div className={'simulatorData'}>
                <div className={'simulatorDataSelect'}>
                  <p> Método de pago </p>
                  <select
                    name="paymentMethod"
                    onChange={handleValueChange}
                    value={values.paymentMethod}
                  >
                    <option value="">Seleccionar</option>
                    {renderpaymentMethodSelect()}
                  </select>
                </div>
                <div className={'simulatorDataInput'}>
                  <p> Ingresa el monto de la venta </p>
                  <Cleave
                    placeholder="0.00"
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: "thousand"
                    }}
                    value={values.mount}
                    onChange={handleValueChange}
                    name="mount"
                  />
                  <span className="unit">$</span>
                </div>
                <div className={'simulatorDataSelect'}>
                  <p> Mensualidades </p>
                  <select
                    name="monthlyPayment"
                    onChange={handleValueChange}
                    value={values.monthlyPayment}
                  >
                    <option value="">Seleccionar</option>
                    {renderMonthlyPaymentSelect()}
                  </select>
                </div>
              </div>
              <div className={'simulatorResult'}>
                <div className={'simulatorResultContainer'}>
                  <div className={'simulatorResultHeader'}>
                    <h4>Recibirás en tu cuenta </h4>
                    <p>
                      ${values.total}
                      <span>MXN</span>
                    </p>
                    {/* <h5> en 2 días hábiles</h5> */}
                  </div>
                  <div className={'simulatorResultFooter'}>
                    <p>
                      Todas las comisiones son desglosadas por tipo de pago.
                      {/* Para mayor información sobre comisiones{" "}
                      <a href={MORE_INFO} target="__blank">
                        clic aquí
                      </a> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
}

export default Simulator;
