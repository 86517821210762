import React, { useState } from "react";
import { Link } from 'react-router-dom';
import ModalVideo from "react-modal-video";

import "assets/styles/home/HeroHome.scss";
import "assets/styles/HeroCommon.scss";

import background from "assets/img/Background.png";
import computer from "assets/img/Computer.png";

import { VIDEO_ID } from "constants/etomin";

import Clearfix from "components/common/Clearfix";
import HeroFooter from "components/common/HeroFooter";

const HeroHome = () => {

  const [isOpen, setIsOpen] = useState(false);

  const closeModal = e => {
    e && e.preventDefault && e.preventDefault();
    setIsOpen(false);
  };
  // const openModal = e => {
  //   e && e.preventDefault && e.preventDefault();
  //   setIsOpen(true);
  // };

  return (
    <div className={'heroWrapper'}>
      <div className={'heroCompleteBackground'} />
      <div className={'hero'}>
        <img
          className={'heroBackground'}
          src={background}
          alt="Background"
        />
        <div className={'heroContent'}>
          <div className={'heroContentLeft'}>
            <div>
              <span className={'heroSubtitle'}>
                Aumenta las ventas de tu negocio hoy mismo
              </span>
              <h1 className={'heroTitle'}>
                Acepta tarjetas de cr&eacute;dito, pagos en OXXO y SPEI
              </h1>
              <div className={'heroButtons'}>
                <Link
                  className={`${'heroButton'} ${
                    'heroButtonGreen'
                    }`}
                  to={'/device'}
                >
                  Cobro con tarjeta
                </Link>
                <Link
                  className={`${'heroButton'} ${
                    'heroButtonGreen'
                    }`}
                  to={'/ecommerce'}
                >
                  Pagos en linea
                </Link>
                {/* <a
                  className={'heroButton'}
                  onClick={openModal}
                  href={"#video"}
                >
                  <img src={video} alt="video" />
                  &nbsp;&nbsp;&nbsp; VER VIDEO
                </a> */}
                <Clearfix />
              </div>
            </div>
          </div>
          <div className={'heroContentRight'}>
            <div className={'heroContentImage'}>
              <img src={computer} alt="desktop" />
            </div>
          </div>
          <HeroFooter />
          <Clearfix />
        </div>
      </div>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={VIDEO_ID}
        onClose={closeModal}
      />
    </div>
  );
}

export default HeroHome;