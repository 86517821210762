import React from "react";

import "assets/styles/home/QuestionsHome.scss";
// import phone from "assets/img/phone.png";
import email from "assets/img/email.png";
import chat from "assets/img/chat.png";
import dudas from "assets/img/dudas.png";

import Title from "components/common/sections/Title";
import Clearfix from "components/common/Clearfix";
import QuestionsHomeCard from "components/home/sections/QuestionsHomeCard";

const QuestionsHome = () => {
  return (
    <div className={'questionsWrapper'}>
      <div className={'questions'}>
        <div className={'questionSection'}>
          <Title text="¿Tienes dudas?" position={"left"} />
          <p>
            Si tienes dudas, no ves reflejados tus pagos o tu <br /> dispositivo
            no carga, estamos siempre para <br />atenderte.
          </p>
          <div className={'questionsHomeCardContainer'}>
            {/* <QuestionsHomeCard
              image={phone}
              imageWidth={"33px"}
              title={"01(55) 2629 9851"}
              text="Llámanos desde todo México de Lunes a Viernes de 8 a 19h"
            /> */}
            <QuestionsHomeCard
              image={email}
              imageWidth={"33px"}
              title={"soporte@etomin.com"}
              text="Escríbenos para brindarte la ayuda que necesitas"
            />
            <QuestionsHomeCard
              image={chat}
              imageWidth={"31px"}
              title={"Chatea con nosotros"}
              text="Hacemos todo lo posible por solucionarte tus dudas"
            />
            <QuestionsHomeCard
              image={dudas}
              imageWidth={"36px"}
              title={"¿Tienes dudas?"}
              text="Visita nuestro Centro de ayuda para resolver tus dudas."
            />
            <Clearfix />
          </div>
          <Clearfix />
        </div>
      </div>
    </div>
  );
};

export default QuestionsHome;
