import React from "react";

import "assets/styles/device/Transactions.scss";
import Title from "components/common/sections/Title";
import secure from "../../../assets/img/secure.png";
import emvco from "../../../assets/img/emvco.svg";

const Transactions = () => {
  return (
    <div className={'transactionsDeviceWrapper'}>
      <div className={'transactionsDevice'}>
        <div className={'transactionsContainer'}>
          <div className={'transactionsDeviceImage'}>
            <img src={secure} alt="secure" />
          </div>
          <div className={'transactionsDeviceInfo'}>
            <Title
              text="Realiza tus transacciones de forma segura"
              position="Left"
            />
            <p className={'transactionsSubtitle'}>
              <ul style={{ listStyle: "list-style-type" }}>
                <li style={{ marginBottom: "20px", marginTop: "10px" }}>
                  Todos los datos viajan codificados desde el dispositivo hasta
                  el emisor de la tarjeta, por lo que los cibercriminales no
                  pueden acceder a la información.
                </li>
                <li style={{ marginBottom: "20px" }}>
                  Ningún dato se queda almacenado en el lector de tarjetas ni en
                  tu smartphone.
                </li>
                <li style={{ marginBottom: "20px" }}>
                  Nuestros poderosos motores antifraude mantienen seguro tu
                  dinero y el de tus clientes.
                </li>
                <li style={{ marginBottom: "20px" }}>
                  En caso de que alguien quisiera hacer mal uso del servicio, el
                  lector y la app se bloquean para garantizar tu seguridad.
                </li>
              </ul>
            </p>
            <img src={emvco} alt="emvco" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
