import React, { useState } from "react";

import "assets/styles/home/PayPlansCarousel.css";
import "assets/styles/home/PayPlansHome.scss";

import Selects from "components/home/sections/Selects";
import Title from "components/common/sections/Title";

import spei from "assets/img/spei-logo.png";
import monitorea from "assets/img/monitorea.svg";

import {
  RED_CARD_SELECT
} from "constants/etomin";

const content = [
  {
    title: "Lleva el control de tu negocio ",
    text:
      "Y ten en tus manos el balance de tus ingresos y gastos en tiempo real."
  },
  {
    title: "Crea Planes de Pago recurrente",
    text: "Ofrece a tus clientes realizar sus compras en pagos periódicos"
  },
  {
    title: "Envía Dinero ",
    text:
      "Realiza operaciones desde donde te encuentres y dispón de tu dinero en tiempo real. "
  }
];

const PayPlansHome = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [items, setItems] = useState(['orange', 'red', 'green']);

  const carouselPrev = () => {
    setCurrentSlide(currentSlide === 0 ? content.length -1 : currentSlide - 1);

    let _items = items;
    const lastItem = _items.pop();
    _items.unshift(lastItem);
    setItems(_items);
  };
  
  const carouselNext = () => {
    setCurrentSlide(currentSlide === content.length -1 ? 0 : currentSlide + 1);
    let _items = items;
    const firstItem = _items.shift();
    _items.push(firstItem);
    setItems(_items);
  };

  const getCartContent = (color) => {
    switch (color) {
      case 'orange':
        return (
          <>
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: "50%",
                transform: "translate(50%, -80%)",
                width: "50%"
              }}
            >
              <img src={monitorea} alt='monitorea' style={{
                position: 'initial',
                margin: 0,
                padding: 0,
                width: '100%'
              }} />
            </div>
            <div className={'itemFooter'}>
              <p style={{fontSize: "16px"}}>
                {"Revisa cada una de tus transacciones en el momento que quieras desde nuestro portal web"}
              </p>
            </div>
          </>
        );

      case 'red':
        return (
          <>
            <Selects data={RED_CARD_SELECT} />
            <div className={'itemFooter'}>
              <p>
                Crea multiples planes de pago. Diario, semanal y mensual
              </p>
            </div>
          </>
        );

      case 'green':
        return (
          <>
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: "50%",
                transform: "translate(50%, -100%)",
                width: "50%"
              }}
            >
              <img src={spei} alt='spei' style={{
                position: 'initial',
                margin: 0,
                padding: 0,
                width: '100%'
              }}/>
            </div>
            <div className={'itemFooter'}>
              <p>
                {"Genera transacciones entre cuentas"}
              </p>
            </div>
          </>
        );
    
      default:
        break;
    }
  };

  return (
    <div className={'payPlansWrapper'}>
      <div className={'payPlans'}>
        <div className={'section'}>
          <Title
            text={content[currentSlide].title}
            position={"left"}
          />
          <p>{content[currentSlide].text}</p>
          <div className={'payPlansButtons'}>
            <a
              className={'roundButton'}
              href="#prev"
              onClick={carouselPrev}
            >
              {'-'}
            </a>
            <a
              className={'roundButton'}
              href="#next"
              onClick={carouselNext}
            >
              {'+'}
            </a>
            <div style={{ clear: "fix" }} />
          </div>
        </div>
        <div className={'section'}>
          <div className={'payPlansCarousel'}>
            <div className={`item1 ${items[0]}`}>
              {getCartContent(items[0])}  
            </div>
            <div className={`item2 ${items[1]}`}>
              {getCartContent(items[1])}  
            </div>
            <div className={`item3 ${items[2]}`}>
              {getCartContent(items[2])}  
            </div>
          </div>
        </div>
        <div style={{ clear: "both" }} />
      </div>
    </div>
  );
}

export default PayPlansHome;
