import React, { Fragment } from "react";

import Header from "../common/layout/Header";
import SubHeader from "../common/layout/SubHeader";
import HeroHome from "./sections/HeroHome";
import SolutionHome from "./sections/SolutionHome";
import AcceptHome from "./sections/AcceptHome";
import AcceptCelHome from "./sections/AcceptCelHome";
import PayPlansHome from "./sections/PayPlansHome";
import QuestionsHome from "./sections/QuestionsHome";
// import OurClientsHome from "components/home/sections/OurClientsHome";
// import ContactHome from "components/home/sections/ContactHome";
import Footer from "../common/layout/Footer";

const HomeScreen = () => {
  return (
    <Fragment>
      <Header inverse />
      <SubHeader inverse />
      <HeroHome />
      <SolutionHome />
      <AcceptHome />
      <AcceptCelHome />
      <PayPlansHome />
      <QuestionsHome />
    {/* 
    //   <OurClientsHome />
    //   <ContactHome />
    //    */}
      <Footer />
    </Fragment>
  );
};

export default HomeScreen;
