import React, { useState } from "react";

import "assets/styles/device/HeroDevice.scss";
import "assets/styles/HeroCommon.scss";
import ModalVideo from "react-modal-video";
import video from "assets/img/etomin-video.svg";

import bgPhone from "assets/img/bg-phone.png";
import phoneAndReader from "assets/img/phone-and-reader.png";
import Clearfix from "components/common/Clearfix";

import HeroFooter from "components/common/HeroFooter";
import { REGISTER_LINK, VIDEO_ID } from "constants/etomin";

const Hero = () => {
  // state = { isOpen: false };

  const [showModal, setShowModal] = useState(false);

  // import phoneAndReader from "assets/phone-and-reader.png";
  // import Clearfix from "components/common/Clearfix";


  // export default class Hero extends Component {
  //   state = { isOpen: false };

  // const closeModal = e => {
  //   e && e.preventDefault && e.preventDefault();
  //   setIsOpen(false);
  // };
  // const openModal = e => {
  //   e && e.preventDefault && e.preventDefault();
    
  // };

  const buyDeviceContainer = () => {
    const _elment = document.getElementById("payment-content");

    _elment.scrollIntoView();
  }

  return (
    <div className={'heroDevice'}>
      <div className={'heroDeviceCompleteBackground'} />
      <div className={'heroDeviceWrapper'}>
        <img
          className={'heroDeviceBackground'}
          src={bgPhone}
          alt="Background"
        />
        <div className={'heroContent'}>
          <div className={'heroContentLeft'}>
            <div>
              <span className={'heroSubtitle'}>
                Acepta tarjetas de crédito y débito y vende más
              </span>
              <h1 className={'heroTitle'}>
                Aumenta las opciones de pago de tu negocio
              </h1>
              <br />
              <br />
              <button onClick={() => buyDeviceContainer()}
                className={`${'heroButton'} ${
                  'heroButtonGreen'
                  }`}
              >
                Comprar
              </button>
              {/* <a
                className={`${'heroButton'} ${
                  'heroButtonGreen'
                }`}
                href={REGISTER_LINK}
                target={"__blank"}
              >
                Registrarse
              </a> */}
              <a
                className={'heroButton'}
                onClick={() => setShowModal(true)}
                href={"#video"}
              >
                <img src={video} alt="video" />
                &nbsp;&nbsp;&nbsp; VER VIDEO
              </a>
              <Clearfix />
            </div>
          </div>
          <div className={'heroContentRight'}>
            <div className={'heroDeviceContentRightImage'}>
              <img
                src={phoneAndReader}
                style={{ width: "100%" }}
                alt={"device"}
              />
            </div>
          </div>
          <HeroFooter />
          <Clearfix />
        </div>
      </div>

      {showModal && <div className={'heroEcommerceModalContainer'}>
        <div className={'heroEcommerceModal'}>
          <div className={'heroEcommerceModalClose'} onClick={() => setShowModal(false)}>
            ✖️
          </div>
          <div className={'heroEcommerceModalFrame'}>
            <ModalVideo
              channel="youtube"
              youtube={{ mute: false, autoplay: true }}
              isOpen={true}
              videoId={VIDEO_ID}
            />
          </div>
        </div>
      </div>}
    </div >
  );
}

export default Hero;