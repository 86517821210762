import React, { Fragment, useState } from "react";
import Modal from "react-modal";
import "assets/styles/device/PaysCard.scss";
import close from "assets/img/close.svg";

const PaysCard = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

    return (
      <Fragment>
        <div className={'commissionsDeviceCardWrapper'}>
          <div className={'commissionsDeviceCardModal'}>
            <div className={'cardHeader'}>
              <div className={'cardHeaderCost'}>
                <p>
                  $300 <span>MXN</span>
                </p>
                <p className={'cardHeaderSend'}>+Envio Gratis</p>
              </div>
            </div>
            <hr />
            <div className={'cardBody'}>
              <p className={'cardBodyItem'}>
                Acepta tarjetas de chip y banda magnética.
              </p>
              <p className={'cardBodyItem'}>
                Compatible con la mayoría de los smartphones.
              </p>
              <p className={'cardBodyItem'}>Soporte vía remota.</p>
              <p className={'cardBodyItem'}>
                Conexión vía Bluetooth, toma de audio o conexión micro USB.
              </p>
              <p className={'cardBodyMoreInfo'}>
                <a href="#" onClick={() => openModal()}>
                  Más información
                </a>
              </p>
            </div>
            {/* <div className={'cardFooter'}>
              <button id="etominbtn-5" onClick={() => gtag_report_conversion('compra-de-lector');}>Comprar lector</button>
            </div> */}
            <div style={{ clear: "both" }} />
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className={'modal'}
          overlayClassName={'overlay'}
        >
          <div className={'modalContainer'}>
            <a href="#" className={'modalClose'} onClick={closeModal}>
              <img className={'modalCloseImage'} src={close} alt="close" />
            </a>
            <h2>Lector Bluetooth para banda magnética y chip</h2>
            <p>
              Este lector de tarjetas pequeño te permitirá ofrecer a tus
              clientes una opción más para el pago de tus productos o servicios.
            </p>
            <p>Los beneficios que ofrece son:</p>
            <ul>
              <li>
                Acepta pagos con tarjeta que cuenten con chip y firma, así como
                aquellas que solo tengan banda magnética EMV (Europay Mastercard
                VISA).
              </li>
              <li>
                Conecta tu dispositivo a tu teléfono o tableta usando su opción
                Bluetooth, toma de audio o tecnología micro USB.
              </li>
              <li>
                Compatible con la mayoría de los smartphones y tabletas con
                Android, iOS, Windows y Linux.
              </li>
              <li>Actualiza tu dispositivo y recibe soporte vía remota.</li>
              <li>
                Cuenta con certificados EMV nivel 1 y 2, VISA Ready, Mastercard
                MPos y AMEX activado.
              </li>
              <li>
                Brinda seguridad a tus clientes gracias a su tecnología de
                encriptación de extremo a extremo que garantiza un
                funcionamiento seguro y confiable en aplicaciones de puntos de
                venta móviles.
              </li>
            </ul>
            <p>Sus características son:</p>
            <ul>
              <li>
                Medidas de 6.18 x 4.45 centímetros para dispositivo con
                Bluetooth y 6.06 x 4.73 para dispositivos con toma de audio.
              </li>
              <li>
                Peso de 29.8 gramos con Bluetooth y 40 gramos con toma de audio.
              </li>
              <li>Indicador LED.</li>
              <li>Batería recargable de litio.</li>
              <li>Cargador micro USB.</li>
              <li>
                Comunicación vía Bluetooth 4.0, Micro USB o Toma de audio de 3.5
                mm y Micro USB.
              </li>
            </ul>
          </div>
        </Modal>
      </Fragment>
    );
}

export default PaysCard;
