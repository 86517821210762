import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "assets/img/logo-white.svg";
import 'assets/styles/layout/Header.scss';
import { REGISTER_LINK } from "constants/etomin";

const Header = ({ inverse }) => {
  // state = { openNavigation: false };
  const [openNavigation, setOpenNavigation] = useState(false);

  const resize = () => {
    setOpenNavigation(false);
  };

  // const goToContact = e => {
  //   const contact = document.getElementById("contact");

  //   if (!contact) {
  //     this.props.history.push("/#contact");
  //     return;
  //   }

  //   if (contact && "scrollIntoView" in contact) {
  //     e.preventDefault();
  //     document.getElementById("contact").scrollIntoView({
  //       behavior: "smooth"
  //     });
  //   }
  // };

  const toggleNavigation = (e) => {
    e && e.preventDefault && e.preventDefault();
    setOpenNavigation(!openNavigation);
  };

  useEffect(() => {
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    }
  }, []);

    return (
      <div className={'headerWrapper'}>
        <header className={'header'}>
          <div className={'brand'}>
            <Link to="/">
              <img src={logo} alt="etomin" />
            </Link>
          </div>
          <div className={'collapse'}>
            <a
              className={'collapseButton'}
              href="#open-navigation"
              onClick={toggleNavigation}
            >
              <i
                className={`${'fa'} ${"fa-lg"} ${"fa-bars"}`}
              />
            </a>
          </div>
          <nav
            className={`${'navigation'} ${
              openNavigation ? 'navigationMobile' : ""
            }`}
          >
            <ul>
              <li>
                <Link to="/device" className={inverse ? 'inverse' : ""}>
                  Cobra con tarjeta
                </Link>
              </li>
              <li>
                {/* <Link to="/ecommerce" className={inverse ? 'inverse' : ""}> */}
                <Link to="/ecommerce" className={inverse ? 'inverse' : ""}>
                  Pagos en l&iacute;nea
                </Link>
              </li>
              {/* <li>
                <a
                  href={"#contact"}
                  onClick={goToContact}
                  className={inverse ? 'inverse' : ""}
                >
                  Contacto
                </a>
              </li> */}
              <li>
                <a
                  className={`${'button'} ${'buttonLast'} ${Boolean(inverse) && 'buttonInverse'}`}
                  href={REGISTER_LINK}
                  target="__blank"
                >
                  Regístrate
                </a>
              </li>
            </ul>
          </nav>
          <div style={{ clear: "both" }} />
        </header>
      </div>
    );

}

export default Header;
