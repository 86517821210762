import React, { Fragment } from "react";

import Header from "components/common/layout/Header";
import SubHeader from "components/common/layout/SubHeader";
import TermsAndConditionsHeader from "components/common/terminsAndConditions/sections/termsAndConditionsHeader";
import TermsAndConditionsContent from "components/common/terminsAndConditions/sections/termsAndConditionsContent";

import Footer from "components/common/layout/Footer";

const TermsAndConditions = () => {

    return (
      <Fragment>
        <Header />
        <SubHeader />
        <TermsAndConditionsHeader />
        <TermsAndConditionsContent />
        <Footer /> 
      </Fragment>
    );

}

export default TermsAndConditions;
