import React, { Fragment } from "react";

import Header from "components/common/layout/Header";
import SubHeader from "components/common/layout/SubHeader";
import PrivacyHeader from "components/common/privacy/sections/privacyHeader";
import PrivacyContent from "components/common/privacy/sections/privacyContent";

import Footer from "components/common/layout/Footer";

const TermsAndConditions = () => {

    return (
      <Fragment>
        <Header />
        <SubHeader />
        <PrivacyHeader />
        <PrivacyContent />
        <Footer /> 
      </Fragment>
    );

}

export default TermsAndConditions;
